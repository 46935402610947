import PropTypes from 'prop-types';

import './style.scss';

export const ToastSuccess = ({ description, icon, title }) => (
    <div className="toast__container">
        <img alt="icon" className="toast__leading" src={icon} />
        <div className="toast__content">
            <h4>
                {title}
            </h4>
            <span>
                {description}
            </span>
        </div>
    </div>
);

ToastSuccess.propTypes = {
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ToastSuccess;
