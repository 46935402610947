import classnames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from 'components/common/BackButton';
import Main from 'components/layout/Main';
import './style.scss';

const MainWithStep = ({
    backButtonTitle,
    children,
    footer,
    title,
    subheader,
    subtitle,
    isLarge,
    isFullWidth,
    onClickOnBackButton,
}) => (
    <Main
        className={classnames('main-steps', { 'main-steps__with-back-button': onClickOnBackButton })}
        isFullWidth={isFullWidth}
        isLarge={isLarge}
    >
        <div className="main-steps__container">
            <div className="main-steps__header">
                {onClickOnBackButton && (
                    <div className="main-steps__back-button">
                        <BackButton handleClick={onClickOnBackButton} text={backButtonTitle} />
                    </div>
                )}
                {subheader && <h4 className="main-steps__subheader">{subheader}</h4>}
                <h1 className="title">{title}</h1>
                {subtitle && <span className="main-steps__subtitle">{subtitle}</span>}
            </div>
            <div className="main-steps__content">{children}</div>
            {footer && <div className="main-steps__footer">{footer}</div>}
        </div>
    </Main>
);

MainWithStep.propTypes = {
    backButtonTitle: PropTypes.string,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    isFullWidth: PropTypes.bool,
    isLarge: PropTypes.bool,
    onClickOnBackButton: PropTypes.func,
    subheader: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export default MainWithStep;
