import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    addSelectedJob,
    getJob,
    getNextCard,
    getNumberJobsAnswered,
    getNumberJobsSelectedDomain,
    getPreviousCard,
    removeLastSelectedJob,
} from 'actions';
import { CardMock } from 'components/Card';
import CardJob from 'components/CardJob';
import ProgressBar from 'components/common/ProgressBar';
import Spinner from 'components/common/Spinner';
import DetailedCardJob from 'components/DetailedCardJob';
import ActionBar from 'components/Steps/Step1/ActionBar';
import SwipeableCard from 'components/SwipeableCard';
import { useAppData } from 'hooks/useAppData';
import useSwipe from 'hooks/useSwipe';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import useUserLanguage from 'hooks/useUserLanguage';
import useWindowSize from 'hooks/useWindowSize';
import ActionType from 'types/ActionType';
import { ROUTES } from 'utils/constants';

import './style.scss';

// the timeout is used to prevent the user to slide too quickly (while the slider animation is running) and induce bugs
const SLIDER_ACTION_TIMEOUT = 500;

const JobSelection = ({ handleStopExploration }) => {
    const { API_URL } = useAppData();
    const {
        areAllCategoriesSelected,
        cardIfDislike,
        cardIfLike,
        current,
        goalReached,
        isExplorationOver,
        previous,
    } = useSelector((state) => state.cards.discovery);
    const job = useSelector((state) => state.job.currentJob);
    const numberJobsSelectedDomain = useSelector((state) => state.job.numberJobsSelectedDomain);
    const initialNumberJobsAnswered = useSelector((state) => state.job.numberJobsAnswered);
    const selectedJobs = useSelector((state) => state.job.selectedJobs);
    const { isPassedInterstitial, currentCardId } = useSelector((state) => state.user.currentForm);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [numberJobsAnswered, setNumberJobsAnswered] = useState(initialNumberJobsAnswered);
    const dispatch = useDispatch();
    const isMobile = 'xs' === useWindowSize().size;
    const userLanguage = useUserLanguage();
    const push = useLocalizedHistoryPush();
    const [isSwipeable, setIsSwipeable] = useState(true);

    // on first render, initiate algorithm
    useEffect(() => {
        if (!current || !cardIfLike || !cardIfDislike) {
            dispatch(getNextCard(API_URL, currentCardId));
        }
        dispatch(getNumberJobsSelectedDomain(API_URL));
        dispatch(getNumberJobsAnswered(API_URL));
    }, []);

    useEffect(() => {
        setNumberJobsAnswered(initialNumberJobsAnswered);
    }, [initialNumberJobsAnswered]);

    // update cards
    useEffect(() => {
        if (current?.id) {
            dispatch(getJob(API_URL, current?.id));
        }
    }, [current]);

    useEffect(() => {
        if (
            // Algo thinks I explored enough jobs
            (goalReached && !isPassedInterstitial && selectedJobs.length > 0)
            // Algo has no more jobs to return for these selected categories
            || (isExplorationOver && !areAllCategoriesSelected)
        ) {
            push(ROUTES.step1.transition);
        } else if (isExplorationOver && areAllCategoriesSelected) {
            handleStopExploration();
        }
    }, [goalReached, isExplorationOver, areAllCategoriesSelected]);

    const handleSwipe = useCallback(async (direction) => {
        setIsSwipeable(false);
        setTimeout(() => { setIsSwipeable(true); }, SLIDER_ACTION_TIMEOUT);
        setNumberJobsAnswered(numberJobsAnswered + 1);
        switch (direction) {
            case 'left':
                await dispatch(getNextCard(API_URL, current.id, ActionType.ACTION_TYPE_DISLIKE));
                if (document.getElementById(`mock-${current.id}`)) {
                    document.getElementById(`mock-${current.id}`).classList.add('card-fade-out');
                }
                break;
            case 'right':
                await dispatch(getNextCard(API_URL, current.id, ActionType.ACTION_TYPE_LIKE));
                await dispatch(addSelectedJob(current.id));
                if (document.getElementById(`mock-${current.id}`)) {
                    document.getElementById(`mock-${current.id}`).classList.add('card-fade-out');
                }
                break;
            default:
                break;
        }
    }, [current]);

    const handleReset = useCallback(async () => {
        if (previous) {
            await dispatch(getPreviousCard(API_URL, previous?.id));
            await dispatch(removeLastSelectedJob());
            setNumberJobsAnswered(numberJobsAnswered - 1);
        }
    }, [previous]);

    const { generateCardProps, resetPreviousCard, swipeCurrentCard } = useSwipe({
        availableDirections: ['left', 'right'],
        onSwipe: handleSwipe,
        onReset: handleReset,
        isKeyboardAvailable: !isMobile && !isDetailOpen && isSwipeable,
        canReset: true,
        resetKey: 'ArrowDown',
    });

    const actionBar = (
        <ActionBar
            onClose={() => setIsDetailOpen(false)}
            onNo={() => swipeCurrentCard('left')}
            onYes={() => swipeCurrentCard('right')}
        />
    );

    if (!job) {
        return <Spinner color="black" />;
    }

    const { id } = job;
    const professionalDomains = job.professionalDomains?.filter((pd) => pd.label && pd.label[userLanguage]);
    const shortDescription = (job.shortDescription && job.shortDescription[userLanguage]) ?? undefined;
    const title = (job.title && job.title[userLanguage]) ?? undefined;

    const percentage = `${(numberJobsAnswered / numberJobsSelectedDomain) * 100}%`;

    return (
        <div className="cards-page__container">
            {
                isDetailOpen && (
                    <DetailedCardJob bottomActionBar={actionBar} job={job} onClose={() => setIsDetailOpen(false)} />
                )
            }
            {
                job && (
                    <div className="cards-page__fragment">
                        <Fragment key={`mock-${id}`}>
                            <div className="cards-page__padded-wrapper">
                                <div className="cards-page__closed-card">
                                    <SwipeableCard
                                        {...generateCardProps(
                                            previous?.id,
                                            current.id,
                                            id,
                                            0,
                                        )}
                                        isSwipeable={isSwipeable}
                                    >
                                        <CardJob
                                            bottomActionBar={actionBar}
                                            categories={professionalDomains}
                                            onBack={() => resetPreviousCard()}
                                            onMoreInfo={() => setIsDetailOpen(true)}
                                            previousCardId={previous?.id}
                                            title={title}
                                        >
                                            {shortDescription && <p>{shortDescription}</p>}
                                        </CardJob>
                                    </SwipeableCard>
                                </div>
                            </div>
                            <div className="cards-page__padded-wrapper cards-page__mock" id={`mock-${id}`}>
                                <div className="cards-page__closed-card">
                                    <CardMock />
                                </div>
                            </div>
                        </Fragment>
                    </div>
                )
            }
            {!job || percentage === '0%' ? '' : (
                <div className="progress-bar-container">
                    <ProgressBar className="progressBar__content--black" percentage={percentage} />
                </div>
            )}
        </div>
    );
};

JobSelection.propTypes = {
    handleStopExploration: PropTypes.func,
};

export default JobSelection;
