import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LanguageContext from 'Language/LanguageContext';
import { ROUTES, USER_ROLES } from 'utils/constants';

const LoginRedirect = () => {
    const { language } = useContext(LanguageContext);
    const { id, status } = useSelector(({ auth }) => auth);
    if (id) {
        if (USER_ROLES.ADMIN === status) {
            return <Redirect to={`/${language}${ROUTES.admin}`} />;
        }

        return <Redirect to={`/${language}${ROUTES.account}`} />;
    }

    return <Redirect to={`/${language}`} />;
};

export default LoginRedirect;
