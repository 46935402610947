import { useTranslation } from 'react-i18next';

import './style.scss';

const KeysGuide = () => {
    const { t } = useTranslation();

    return (
        <div className="keys-guide__wrapper">
            <div className="keys-guide__guideBlock">
                <p className="keys-guide__title">{t('key.guide.title')}</p>
                <div className="keys-guide__key">
                    <img alt={t('key.guide.no')} src="/assets/icons/key-no-desktop.svg" />
                    <p>{t('key.guide.no')}</p>
                </div>
                <div className="keys-guide__key">
                    <img alt={t('key.guide.yes')} src="/assets/icons/key-yes-desktop.svg" />
                    <p>{t('key.guide.yes')}</p>
                </div>
                <div className="keys-guide__key">
                    <img alt={t('key.guide.back')} src="/assets/icons/key-back-desktop.svg" />
                    <p>{t('key.guide.back')}</p>
                </div>
            </div>
        </div>
    );
};

export default KeysGuide;
