import PropTypes from 'prop-types';
import { useState } from 'react';
import DetailedCardJob from 'components/DetailedCardJob';
import useUserLanguage from 'hooks/useUserLanguage';
import { formatDescriptionPreview } from 'utils/helpers';

import './style.scss';

const SimilarJobCard = ({ job }) => {
    const userLanguage = useUserLanguage();
    const [showJobDetails, setShowJobDetails] = useState(false);
    const title = job.title && job.title[userLanguage] ? job.title[userLanguage] : '';
    const shortDescription = job.shortDescription && job.shortDescription[userLanguage]
        ? formatDescriptionPreview(job.shortDescription[userLanguage])
        : '';

    return (
        showJobDetails ? (
            <DetailedCardJob
                job={job}
                onClose={() => setShowJobDetails(false)}
            />
        ) : (
            <button
                className="similar-job-card"
                onClick={() => {
                    setShowJobDetails(true);
                }}
                type="button"
            >
                <h4>{title}</h4>
                {
                    shortDescription
                    && (
                        <span className="similar-job-card__description">
                            {shortDescription}
                        </span>
                    )
                }
            </button>
        )
    );
};

SimilarJobCard.propTypes = {
    job: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shortDescription: PropTypes.shape({}),
        title: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default SimilarJobCard;
