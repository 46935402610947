import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import auth from '../jt-client/reducers/auth';
import cards from './cards';
import job from './job';
import user from './user';

const rootReducer = combineReducers({
    auth,
    cards,
    form,
    job,
    user,
});

export default rootReducer;
