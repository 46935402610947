import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

const DescriptionExercise = ({ children, step, type = 'long', hiddenDescription = false }) => {
    const { t } = useTranslation();

    return (
        <div className={`descriptionExercise descriptionExercise__${type}`}>
            <h3>{t(`step${step}.${type}Description.title`)}</h3>
            {!hiddenDescription && (
                <>
                    <p><Trans i18nKey={`step${step}.${type}Description.text`} /></p>
                    {children}
                </>
            )}
        </div>
    );
};

DescriptionExercise.propTypes = {
    children: PropTypes.node,
    hiddenDescription: PropTypes.bool,
    step: PropTypes.oneOf([1, 2, 3]).isRequired,
    type: PropTypes.oneOf(['long', 'short']),
};

export default DescriptionExercise;
