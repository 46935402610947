import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getSelectedJobs, saveTop5 } from 'actions';
import Button from 'components/common/Button';
import DetailedCardJob from 'components/DetailedCardJob';
import Container from 'components/layout/Container';
import MainWithStep from 'components/layout/MainWithStep';
import Panel from 'components/layout/Panel';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import StepList from 'components/StepList';
import ActionBar from 'components/Steps/Step2/ActionBar';
import SelectableJobCard from 'components/Steps/Step2/SelectableJobCard';
import { useAppData } from 'hooks/useAppData';
import useLocalizedHistoryPush from 'hooks/useLocalizedHistoryPush';
import { ROUTES } from 'utils/constants';
import { dismissToasts, toastSuccess } from 'utils/toast';

import './style.scss';

const MAX_JOBS_SELECTED = 5;

const Step2 = () => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const { step1Completed, step2Completed } = useSelector((state) => state.user.currentForm);
    const selectedJobs = useSelector((state) => state.job.selectedJobs);
    const dispatch = useDispatch();
    const [top5Selection, setTop5Selection] = useState([]);
    const [currentJob, setCurrentJob] = useState(null);
    const [showJobDetails, setShowJobDetails] = useState(false);
    const push = useLocalizedHistoryPush();

    const showInstructions = () => {
        confirmAlert({
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step={2} />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        if (step2Completed) {
            push(ROUTES.dashboard.main);
        }

        showInstructions();

        dispatch(getSelectedJobs(API_URL));
    }, []);

    if (!step1Completed) {
        return <Redirect to={ROUTES.step1.onboarding} />;
    }

    const onJobClick = (jobId, selected) => {
        if (!selected) {
            dismissToasts();
            setTop5Selection(top5Selection.filter((id) => jobId !== id));
        } else {
            setTop5Selection([...top5Selection, jobId]);
            if (top5Selection.length + 1 === MAX_JOBS_SELECTED) {
                toastSuccess(t('step2.toast.title'), t('step2.toast.description'));
            }
        }
    };

    const onJobInfoClick = (display, job = null) => {
        setCurrentJob(job);
        setShowJobDetails(display);
    };

    const isJobSelected = (jobId) => top5Selection.indexOf(jobId) !== -1;

    const actionBar = (jobId, selected) => (
        <ActionBar onClick={() => onJobClick(jobId, !selected)} selected={selected} />
    );

    const validate = () => {
        const top5JobsCounter = top5Selection.length;

        return top5JobsCounter > 0 && top5JobsCounter <= MAX_JOBS_SELECTED;
    };

    const onSubmit = () => {
        dispatch(saveTop5(API_URL, top5Selection)).then(() => push(ROUTES.step2.congrats));
    };

    return (
        <Container>
            <Panel>
                <StepList step={2} />
            </Panel>
            <MainWithStep
                footer={(
                    <Button
                        className="button button--black button--block-mobile"
                        disabled={!validate()}
                        onClick={onSubmit}
                        text={t('step2.validate')}
                    />
                )}
                subheader={t('step2.onboarding.stepNumber')}
                title={t('step2.title')}
                isLarge
            >
                {
                    showJobDetails && (
                        <DetailedCardJob
                            bottomActionBar={actionBar(currentJob.id, isJobSelected(currentJob.id))}
                            job={currentJob}
                            onClose={() => onJobInfoClick(false)}
                            topActionBar={actionBar(currentJob.id, isJobSelected(currentJob.id))}
                        />
                    )
                }
                <div className="main-step2__cards-container">
                    {selectedJobs.map((job) => (
                        <SelectableJobCard
                            key={job.id}
                            isDisabled={!isJobSelected(job.id) && top5Selection.length === MAX_JOBS_SELECTED}
                            isSelected={isJobSelected(job.id)}
                            job={job}
                            onClick={(jobId, selected) => onJobClick(jobId, selected)}
                            onDetailClick={(display, jobToDisplay) => onJobInfoClick(display, jobToDisplay)}
                        />
                    ))}
                </div>
            </MainWithStep>
        </Container>
    );
};

export default Step2;
