import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './style.scss';

const ActionBar = ({ onClick, selected = false }) => {
    const { t } = useTranslation();

    return (
        <div className="step2__action-bar__wrapper">
            <button
                className={classNames('button', { 'button--selected': selected })}
                onClick={() => onClick()}
                type="button"
            >
                <img alt="favorite" src="/assets/icons/heart-black.svg" />
                {selected ? t('action.selected_job') : t('action.select_job')}
            </button>
        </div>
    );
};

ActionBar.propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
};

export default ActionBar;
