import { Trans, useTranslation } from 'react-i18next';
import ShareModalButton from 'components/common/ShareModalButton';
import './style.scss';

const DescriptionDashboard = () => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="main-dashboard__panel__title">{t('dashboard.panel.title')}</h1>
            <p className="main-dashboard__panel__description"><Trans i18nKey="dashboard.panel.description" /></p>
            <ShareModalButton />
        </>
    );
};

export default DescriptionDashboard;
