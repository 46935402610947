import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import StepList from 'components/StepList';
import { ROUTES } from 'utils/constants';

import './style.scss';

const Step2Congrats = () => {
    const { t } = useTranslation();
    const { currentForm: { id, step2Completed }, rotiFormId } = useSelector((state) => state.user);

    if (!step2Completed) {
        return <Redirect to={ROUTES.step2.onboarding} />;
    }

    return (
        <Container>
            <Panel><StepList step={3} /></Panel>
            <Main className="step2-congrats__main">
                <h1>{t('step2.congrats.title')}</h1>
                <h2 className="base-margin">
                    <strong>{t('step2.congrats.pretext')}</strong>
                    <br/>
                    {t('step2.congrats.text1')}
                </h2>
                {!rotiFormId && <h2 className="base-margin"><Trans i18nKey="step2.congrats.text2" /></h2>}
                <h2 className="base-margin"><Trans i18nKey="step2.congrats.text3" /></h2>
                <Link
                    className="button button--black button--block-mobile center base-margin"
                    to={`${ROUTES.dashboard.main}/${id}`}
                >
                    {!rotiFormId
                        ? t('step2.congrats.button')
                        : t('step2.congrats.see_results')}
                </Link>
            </Main>
        </Container>
    );
};

export default Step2Congrats;
