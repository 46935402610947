import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getVideoLink } from '../../../utils/video';
import './style.scss';

const Video = ({ videoId, title }) => {
    const { t } = useTranslation();

    if (!videoId) {
        return null;
    }

    return (
        <div className="videoContainer">
            <iframe
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="iframe"
                frameBorder="0"
                scrolling="auto"
                src={getVideoLink(videoId)}
                title={t('video.iframe.title', { jobTitle: title })}
                allowFullScreen
            />
        </div>
    );
};

Video.propTypes = {
    title: PropTypes.string,
    videoId: PropTypes.string.isRequired,
};

export default Video;
