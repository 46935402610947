import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
    clearCurrentCard,
    completeStep1,
    getNumberJobsAnswered,
    getNumberJobsSelectedDomain,
    getSelectedJobs,
    updateCurrentForm,
} from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionBreak from 'components/StepDescription/DescriptionBreak';
import { useAppData } from 'hooks/useAppData';

import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Step1Transition = () => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const dispatch = useDispatch();
    const [redirectLink, setRedirectLink] = useState();
    const selectedJobs = useSelector((state) => state.job.selectedJobs);
    const numberJobsSelectedDomain = useSelector((state) => state.job.numberJobsSelectedDomain);
    const numberJobsAnswered = useSelector((state) => state.job.numberJobsAnswered);
    const { step1Completed, selectedDomains } = useSelector((state) => state.user?.currentForm);
    const [numberJobsRemaining, setNumberJobsRemaining] = useState(0);

    useEffect(() => {
        if (selectedDomains) {
            dispatch(getNumberJobsSelectedDomain(API_URL));
            dispatch(getNumberJobsAnswered(API_URL));
            dispatch(getSelectedJobs(API_URL));
        }
    }, []);

    useEffect(() => {
        if (
            numberJobsAnswered && numberJobsSelectedDomain
            && !Number.isNaN(numberJobsAnswered) && !Number.isNaN(numberJobsSelectedDomain)
        ) {
            setNumberJobsRemaining(numberJobsSelectedDomain - numberJobsAnswered);
        }
    }, [numberJobsSelectedDomain, numberJobsAnswered]);

    const continueForm = async (redirectTo) => {
        if (redirectTo === ROUTES.step1.congrats) {
            await dispatch(completeStep1(API_URL)).then(() => push(redirectTo));
        }

        if (numberJobsRemaining === 0) {
            dispatch(clearCurrentCard());
        }

        await dispatch(updateCurrentForm(API_URL, { isPassedInterstitial: true })).then(() => push(redirectTo));
    };

    if (selectedDomains?.length === 0) {
        return <Redirect to={ROUTES.step1.categories} />;
    }

    if (step1Completed) {
        return <Redirect to={ROUTES.step2.onboarding} />;
    }

    if (selectedJobs?.length === 0) {
        return <Redirect to={ROUTES.step1.thanks} />;
    }

    if (!numberJobsSelectedDomain || !numberJobsAnswered) {
        return null;
    }

    const onGoToStep2 = () => {
        setRedirectLink(ROUTES.step1.congrats);
    };

    return (
        <Container>
            <Panel><DescriptionBreak /></Panel>
            <Main className="main-step1-transition__container">
                <h1><Trans count={selectedJobs.length} i18nKey="step1.transition.title" values={{ jobCount: selectedJobs.length }} /></h1>
                <div className="main-step1-transition__content">
                    {numberJobsRemaining > 0 && (
                        <span className="main-step1-transition__direction">
                            <Trans count={numberJobsRemaining} i18nKey="step1.transition.direction" />
                        </span>
                    )}
                    <div className="main-step1-transition__questions">
                        <input
                            className="main-step1-transition__input"
                            id="option2"
                            name="step1-transition-form"
                            onClick={() => setRedirectLink(ROUTES.step1.categories)}
                            type="radio"
                        />
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="main-step1-transition__label" htmlFor="option2">
                            <span><Trans i18nKey="step1.transition.option2" /></span>
                        </label>
                        <input
                            className="main-step1-transition__input"
                            id="option1"
                            name="step1-transition-form"
                            onClick={onGoToStep2}
                            type="radio"
                        />
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="main-step1-transition__label" htmlFor="option1">
                            <span><Trans i18nKey="step1.transition.option1" /></span>
                        </label>
                        <Button
                            className="button--black button--block-mobile"
                            disabled={!redirectLink}
                            onClick={() => continueForm(redirectLink)}
                            text={t('common.next')}
                        />
                    </div>
                </div>
            </Main>
        </Container>
    );
};

export default Step1Transition;
