import { useTranslation } from 'react-i18next';
import './style.scss';

const DescriptionBreak = () => {
    const { t } = useTranslation();

    return (
        <div className="description-break">
            <h1 className="description-break__title">{t('step1.transition.panel.title')}</h1>
            <img alt="" className="description-break__image" src="/assets/pause.svg" />
        </div>
    );
};

export default DescriptionBreak;
