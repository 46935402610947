import { useDispatch } from 'react-redux';
import { cleanUser } from 'actions';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { logout } from 'jt-client/actions';

const useLogout = () => {
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();

    return () => {
        push('/');
        dispatch(logout());
        dispatch(cleanUser());
    };
};

export default useLogout;
