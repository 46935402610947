import { SubmissionError } from 'redux-form';
import { RESET_CARDS } from 'actions/cards';
import { SET_CURRENT_FORM_VALUES } from 'actions/form';
import { RESET_JOB } from 'actions/job';
import { POST } from 'utils/http';

export const saveSelectedCategories = (API_URL, categories) => async (dispatch) => {
    try {
        const selectedCategories = await POST(`${API_URL}/professional-domains/selected`, { categories });
        dispatch({
            type: SET_CURRENT_FORM_VALUES,
            selectedDomains: selectedCategories,
        });
        dispatch({ type: RESET_JOB });
        dispatch({ type: RESET_CARDS });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export default saveSelectedCategories;
