import { useSelector } from 'react-redux';

import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { goToResults, ROUTES } from 'utils/constants';

const LastResults = () => {
    const { user: { lastCompletedFormId } } = useSelector((state) => state);
    const push = useLocalizedHistoryPush();

    // if a value is found, redirects to the correct result
    if (lastCompletedFormId) {
        push(goToResults(lastCompletedFormId));

        return null;
    }

    // if null, there is no last completed forms so redirects the user to its account
    if (lastCompletedFormId === null) {
        push(ROUTES.account);

        return null;
    }

    // otherwise, lastCompletedForm is undefined because the getLastForms request isn't complete
    return <p>loading...</p>;
};

export default LastResults;
