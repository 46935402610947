import { SET_CARDS, RESET_CARDS } from 'actions/cards';

const initialState = {
    // use to discover jobs
    discovery: {
        previous: null,
        current: null,
        cardIfLike: null,
        cardIfDislike: null,
        goalReached: false,
        isExplorationOver: false,
        areAllCategoriesSelected: false,
    },
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_CARDS:
            return {
                ...state,
                discovery: {
                    previous: payload.previous ? { id: payload.previous } : null,
                    current: { id: payload.current },
                    cardIfLike: { id: payload.cardIfLike },
                    cardIfDislike: { id: payload.cardIfDislike },
                    goalReached: payload.goalReached ?? false,
                    isExplorationOver: payload.isExplorationOver ?? false,
                    areAllCategoriesSelected: payload.areAllCategoriesSelected ?? false,
                },
            };
        case RESET_CARDS:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
