import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from 'components/home/HomeFooter';
import './style.scss';

const ErrorPage = ({ children, content, title }) => {
    const { t } = useTranslation();

    return (
        <div className="error-page__container">
            <header className="error-page__header">
                <Link
                    title={t('general.account')}
                    to="/"
                >
                    <img
                        alt="Logo"
                        height="30"
                        src="/assets/vasco-logo-black.svg"
                    />
                </Link>
            </header>
            <main className="error-page-main">
                <div className="error-page-main__content">
                    <h1>{title}</h1>
                    <span>{content}</span>
                    {children}
                    <img alt="" aria-hidden="true" src="/assets/error.svg" />
                </div>
            </main>
            <Footer />
        </div>
    );
};

ErrorPage.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default ErrorPage;
