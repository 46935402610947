export const getVideoLink = (videoId) => {
    if (videoId.length === 11) {
        return `https://www.youtube.com/embed/${videoId}`;
    }
    const jwPlayerId = 'PYEgl7Mv';

    return `https://cdn.jwplayer.com/players/${videoId}-${jwPlayerId}.html`;
};

export default getVideoLink;
