import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';
import './style.scss';

const DeleteAccountModal = ({ onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('deleteAccount.title')} fullscreen>
            <p>{t('deleteAccount.text')}</p>
            <p>{t('deleteAccount.text.previousTools')}</p>
            <div className="delete-account__buttons">
                <button
                    className="button button--block-mobile button--grey base-margin"
                    onClick={onClose}
                    type="button"
                >
                    {t('modal.cancel')}
                </button>
                <button
                    className="button button--block-mobile button--black base-margin"
                    onClick={() => { onClose(); onConfirm(); }}
                    type="button"
                >
                    {t('deleteAccount.yes')}
                </button>
            </div>
        </Modal>
    );
};

DeleteAccountModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};

export default DeleteAccountModal;
