import { useDispatch } from 'react-redux';
import { createRoti, updateUser } from 'actions';
import FormRotiAndSocioDemo from 'components/EndForm/FormRotiAndSocioDemo';
import Main from 'components/layout/Main';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { ROUTES } from 'utils/constants';

const MainEndForm = () => {
    const dispatch = useDispatch();
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();

    const submitForm = async (values) => {
        const {
            // user values
            ageRange,
            gender,
            status,
            diploma,
            job,
            reasons,
            // roti values
            satisfaction,
            difficulty,
            aestheticism,
            duration,
            content,
        } = values;
        const userValues = { ageRange, gender, status, diploma, job, reasons };
        const rotiValues = { satisfaction, difficulty, aestheticism, duration, content };
        await dispatch(updateUser(API_URL, userValues));
        await dispatch(createRoti(API_URL, rotiValues));
    };

    const goToResults = () => {
        push(ROUTES.dashboard.main);
    };

    return (
        <Main className="no-padding" isLarge>
            <FormRotiAndSocioDemo
                onSubmit={submitForm}
                onSubmitSuccess={goToResults}
            />
        </Main>
    );
};

export default MainEndForm;
