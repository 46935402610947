import classnames from 'classnames';
import PropTypes from 'prop-types';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import './style.scss';

const generateStep = (step, currentStep, children) => {
    const hiddenDescription = step !== currentStep;

    return (
        <div className={classnames('stepList__step', { 'stepList__step--hidden': hiddenDescription })}>
            <DescriptionExercise hiddenDescription={hiddenDescription} step={step} type="short">
                {children}
            </DescriptionExercise>
        </div>
    );
};

const StepList = ({ children, step }) => (
    <ul className="step-list__wrapper">
        {generateStep(1, step, children)}
        {generateStep(2, step, children)}
        {generateStep(3, step, children)}
    </ul>
);

StepList.propTypes = {
    children: PropTypes.node,
    step: PropTypes.oneOf([1, 2, 3]).isRequired,
};

export default StepList;
