import PropTypes from 'prop-types';
import Congrats from 'components/Congrats';
import DescriptionDashboard from 'components/StepDescription/DescriptionDashboard';
import { ROUTES } from 'utils/constants';

const DashboardCongrats = ({ match }) => {
    const { formId } = match.params;

    return (
        <Congrats
            panelChildren={<DescriptionDashboard />}
            redirectTo={`${ROUTES.dashboard.main}${formId ? `/${formId}` : ''}`}
            step={3}
        />
    );
};

DashboardCongrats.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string,
        }),
    }),
};

export default DashboardCongrats;
