import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Congrats from 'components/Congrats';
import StepList from 'components/StepList';
import { ROUTES } from 'utils/constants';

const Step1Congrats = () => {
    const { step1Completed, step2Completed, selectedDomains } = useSelector((state) => state.user?.currentForm);

    if (selectedDomains?.length === 0) {
        return <Redirect to={ROUTES.step1.categories} />;
    }

    if (!step1Completed) {
        return <Redirect to={ROUTES.step1.form} />;
    }

    if (step2Completed) {
        return <Redirect to={ROUTES.dashboard.main} />;
    }

    return <Congrats panelChildren={<StepList step={1} />} redirectTo={ROUTES.step2.onboarding} step={1} />;
};

export default Step1Congrats;
