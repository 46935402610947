import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAppData } from 'hooks/useAppData';
import './style.scss';
import '../input.scss';
import fieldPropTypes from 'utils/fieldPropTypes';

const SimpleField = ({
    className = '',
    input,
    label,
    labelHidden = false,
    maxLength,
    placeholder,
    type,
    meta: { touched, error, warning },
    ...props
}) => {
    const { t } = useTranslation();
    const { FREYA_URL, HESTER_URL, MARCO_V3_URL } = useAppData();

    let err = error;
    let warn = warning;
    let warnValues = {};

    if ('emailAlreadyUsed' === error) {
        err = undefined;
        warn = 'error.email.alreadyUsed';
        warnValues = { FREYA_URL, HESTER_URL, MARCO_V3_URL };
    }

    return (
        <div
            className={`
                field
                ${touched && err ? 'field--error' : ''}
                ${touched && warn ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label
                className={labelHidden ? 'field__label--hidden' : 'margin-bottom-4'}
                htmlFor={input.name}
            >
                {label}
            </label>
            <input
                className="field__input"
                {...input}
                {...props}
                id={input.name}
                maxLength={maxLength}
                placeholder={placeholder}
                type={type}
            />
            {touched
                && ((err && <span>{t(err)}</span>)
                    // eslint-disable-next-line react/no-danger
                    || (warn && <span dangerouslySetInnerHTML={{ __html: t(warn, warnValues) }} />))}
        </div>
    );
};

SimpleField.propTypes = {
    ...fieldPropTypes(PropTypes.string.isRequired),
    maxLength: PropTypes.number,
    type: PropTypes.string.isRequired,
};

export default SimpleField;
