import PropTypes from 'prop-types';

const TitledSection = ({ children, title, contentAsHtml = false }) => (
    <>
        <p className="strong margin-top--large">{title}</p>
        {contentAsHtml ? (
            <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: children,
                }}
            />
        ) : (
            <p>{children}</p>
        )}
    </>
);

TitledSection.propTypes = {
    children: PropTypes.node.isRequired,
    contentAsHtml: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default TitledSection;
