import PropTypes from 'prop-types';
import './style.scss';

const ActionBar = ({ onClose, onNo, onYes }) => (
    <div className="step1__action-bar__wrapper">
        <button onClick={() => { onClose(); onNo(); }} type="button">
            <img alt="no" src="/assets/icons/cross.svg" />
        </button>
        <button onClick={() => { onClose(); onYes(); }} type="button">
            <img alt="yes" src="/assets/icons/heart.svg" />
        </button>
    </div>
);

ActionBar.propTypes = {
    onClose: PropTypes.func,
    onNo: PropTypes.func.isRequired,
    onYes: PropTypes.func.isRequired,
};

export default ActionBar;
