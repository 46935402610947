import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, submit } from 'redux-form';
import { completeStep1, saveSelectedCategories } from 'actions';
import Button from 'components/common/Button';
import SelectableField from 'components/common/form/SelectableField';
import Container from 'components/layout/Container';
import MainWithStep from 'components/layout/MainWithStep';
import Panel from 'components/layout/Panel';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import StepList from 'components/StepList';
import FormCategorySelection from 'components/Steps/Step1/FormCategorySelection';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import useUserLanguage from 'hooks/useUserLanguage';
import { ROUTES } from 'utils/constants';
import { GET } from 'utils/http';
import './style.scss';

const CategorySelection = ({ formIsValid }) => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const userLanguage = useUserLanguage();
    const {
        isPassedInterstitial,
        selectedDomains,
        step1Completed,
    } = useSelector((state) => state.user?.currentForm);
    const { isExplorationOver } = useSelector((state) => state.cards.discovery);
    const selectedJobs = useSelector((state) => state.job.selectedJobs);
    const currentSelectedCategories = useSelector((state) => state.form.formCategorySelection?.values) ?? {};
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const showInstructions = () => {
        confirmAlert({
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step={1} />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        if (step1Completed || (selectedDomains?.length > 0 && !isPassedInterstitial)) {
            push(ROUTES.step1.form);
        }

        if (selectedDomains?.length === 0) {
            showInstructions();
        }

        const getCategories = async () => {
            const result = await GET(`${API_URL}/professional-domains/all`);

            // Filter categories without translated label or if isPassedInterstitial categories already selected
            setCategories(
                result
                    ?.filter((c) => {
                        if (
                            isPassedInterstitial
                            && selectedDomains.findIndex(
                                ({ professionalDomainId }) => professionalDomainId === c.id,
                            ) !== -1
                        ) {
                            return false;
                        }

                        return c.label && c.label[userLanguage];
                    }),
            );
            setLoading(false);
        };

        getCategories();
    }, []);

    const handleSubmit = async (values) => {
        await dispatch(saveSelectedCategories(API_URL, values)).then(() => push(ROUTES.step1.form));
    };

    const stopExploration = async () => {
        if (selectedJobs?.length > 0) {
            await dispatch(completeStep1(API_URL))
                .then(() => push(ROUTES.step1.congrats));
        } else {
            push(ROUTES.step1.thanks);
        }
    };

    const onClickSelectAllCategories = ({ target: { checked } }) => {
        categories.forEach(({ id }) => dispatch(change('formCategorySelection', id, checked)));
    };

    return (
        <Container className="category-selection">
            <Panel>
                <StepList step={1} />
            </Panel>
            <MainWithStep
                backButtonTitle={t('common.back')}
                footer={(
                    <>
                        <Button
                            className="button--black button--block-mobile"
                            disabled={!formIsValid}
                            onClick={() => dispatch(submit('formCategorySelection'))}
                            text={!isPassedInterstitial ? t('common.continue') : t('step.transition.submit')}
                        />
                        {isPassedInterstitial && !isExplorationOver
                            ? (
                                <Button
                                    className="button--outline button--block-mobile small-margin button--center"
                                    onClick={() => push(ROUTES.step1.form)}
                                    text={t('step.transition.continue')}
                                />
                            ) : isPassedInterstitial && selectedJobs.length > 0 && (
                                <Button
                                    className="button--outline button--block-mobile small-margin button--center"
                                    onClick={stopExploration}
                                    text={t('step.transition.stopExploration')}
                                />
                            )}
                    </>
                )}
                onClickOnBackButton={isPassedInterstitial ? () => push(ROUTES.step1.transition) : undefined}
                {...(isPassedInterstitial
                    ? { subtitle: t('step.transition.title.3'), title: t('step.transition.title.2') }
                    : { subheader: t('step.intro.title.1'), title: t('step.intro.title.2') }
                )}
            >
                {loading
                    ? <div>Loading...</div>
                    : (
                        <div className="category-selection__content">
                            {!isPassedInterstitial && categories.length > 0 && (
                                <>
                                    <div className="category-selection__select-all">
                                        <SelectableField
                                            input={{
                                                name: 'allCategories',
                                                onChange: onClickSelectAllCategories,
                                                value: Object.values(currentSelectedCategories)
                                                    .filter((val) => val).length === categories.length,
                                            }}
                                            label={t('step.intro.select.all')}
                                            withoutBorder
                                        />
                                    </div>
                                    <h4>{t('step.intro.title.3')}</h4>
                                </>
                            )}
                            <FormCategorySelection
                                categories={categories}
                                onSubmit={handleSubmit}
                            />
                        </div>
                    )}
            </MainWithStep>
        </Container>
    );
};

CategorySelection.propTypes = {
    formIsValid: PropTypes.bool.isRequired,
};

export default connect(({
    form: { formCategorySelection: { initial, values } = { initial: {}, values: {} } },
}) => ({
    formIsValid: (
        values !== undefined // there is at least one selection
        && Object.entries(values).some(([id, value]) => (
            value // at least one value is at true
            && !(id in initial) // and not already selected
        ))
    ),
}))(CategorySelection);
