import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../input.scss';
import fieldPropTypes from 'utils/fieldPropTypes';

const TextAreaField = ({
    className = '',
    input,
    label,
    labelHidden = false,
    maxLength,
    placeholder,
    type,
    meta: { touched, error, warning },
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={`
                field
                ${touched && error ? 'field--error' : ''}
                ${touched && warning ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label
                className={labelHidden ? 'field__label--hidden' : 'margin-bottom-4'}
                htmlFor={input.name}
            >
                {label}
            </label>
            <textarea
                className="field__input"
                {...input}
                {...props}
                id={input.name}
                maxLength={maxLength}
                placeholder={placeholder}
            />
            {touched
                && ((error && <span>{t(error)}</span>)
                    // eslint-disable-next-line react/no-danger
                    || (warning && <span dangerouslySetInnerHTML={{ __html: t(warning) }} />))}
        </div>
    );
};

TextAreaField.propTypes = {
    ...fieldPropTypes(PropTypes.string.isRequired),
    maxLength: PropTypes.number,
};

export default TextAreaField;
