import { SubmissionError } from 'redux-form';
import { GET, POST } from 'utils/http';

export const ADD_SELECTED_JOB = 'ADD_SELECTED_JOB';
export const REMOVE_LAST_SELECTED_JOB = 'REMOVE_LAST_SELECTED_JOB';
export const RESET_JOB = 'RESET_JOB';
export const SET_JOB = 'SET_JOB';
export const SET_JOB_OFFERS = 'SET_JOB_OFFERS';
export const SET_NUMBER_JOBS_ANSWERED = 'SET_NUMBER_JOBS_ANSWERED';
export const SET_NUMBER_JOBS_SELECTED_DOMAIN = 'SET_NUMBER_JOBS_SELECTED_DOMAIN';
export const SET_SELECTED_JOBS = 'SET_SELECTED_JOBS';
export const SET_SIMILAR_JOBS = 'SET_SIMILAR_JOBS';
export const SET_TOP5_JOBS = 'SET_TOP5_JOBS';

export const getJob = (API_URL, jobId, formId) => async (dispatch) => {
    try {
        const url = new URL(`${API_URL}/jobs/${jobId}`);

        if (formId) {
            url.searchParams.append('formId', formId);
        }

        const data = await GET(url);

        dispatch({
            type: SET_JOB,
            currentJob: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getJobWithTasks = (API_URL, jobId, formId) => async (dispatch) => {
    try {
        const url = new URL(`${API_URL}/jobs/${jobId}/tasks`);

        if (formId) {
            url.searchParams.append('formId', formId);
        }

        const data = await GET(url);

        dispatch({
            type: SET_JOB,
            currentJob: data.currentJob,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const setJob = (job) => ({
    type: SET_JOB,
    currentJob: job,
});

export const addSelectedJob = (selectedJob) => async (dispatch) => {
    dispatch({
        type: ADD_SELECTED_JOB,
        selectedJob,
    });
};

export const removeLastSelectedJob = () => async (dispatch) => {
    dispatch({ type: REMOVE_LAST_SELECTED_JOB });
};

export const getSelectedJobs = (API_URL) => async (dispatch) => {
    try {
        const url = new URL(`${API_URL}/jobs/selected`);
        const data = await GET(url);

        dispatch({
            type: SET_SELECTED_JOBS,
            selectedJobs: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getTop5 = (API_URL, formId, language) => async (dispatch) => {
    try {
        const url = new URL(`${API_URL}/jobs/top5`);
        url.searchParams.append('formId', formId);
        url.searchParams.append('language', language);

        const data = await GET(url);

        dispatch({
            type: SET_TOP5_JOBS,
            top5: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const saveTasks = (API_URL, jobId, tasks, formId) => async (dispatch) => {
    try {
        const data = await POST(`${API_URL}/jobs/${jobId}/tasks`, { formId, tasks });
        dispatch({
            type: SET_JOB,
            currentJob: data.currentJob,
        });

        return { areAllTasksCompleted: data.areAllTasksCompleted ?? false };
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getSimilarJobs = (API_URL, jobId) => async (dispatch) => {
    try {
        const data = await GET(`${API_URL}/jobs/${jobId}/similar`);

        dispatch({
            type: SET_SIMILAR_JOBS,
            similarJobs: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getJobOffers = (API_URL, jobId, language) => async (dispatch) => {
    try {
        const data = await GET(`${API_URL}/jobs/${jobId}/offers/${language}`);

        dispatch({
            type: SET_JOB_OFFERS,
            jobId,
            jobOffers: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getNumberJobsSelectedDomain = (API_URL) => async (dispatch) => {
    try {
        const data = await GET(`${API_URL}/form/jobs/count`);

        dispatch({
            type: SET_NUMBER_JOBS_SELECTED_DOMAIN,
            numberJobsSelectedDomain: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getNumberJobsAnswered = (API_URL) => async (dispatch) => {
    try {
        const data = await GET(`${API_URL}/jobs/answers/count`);

        dispatch({
            type: SET_NUMBER_JOBS_ANSWERED,
            numberJobsAnswered: data,
        });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
