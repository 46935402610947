import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import SelectableField from 'components/common/form/SelectableField';
import useUserLanguage from 'hooks/useUserLanguage';
import { ROUTES } from 'utils/constants';
import './style.scss';

const FormCategorySelection = ({ categories, error, handleSubmit }) => {
    const userLanguage = useUserLanguage();

    if (categories.length === 0) {
        return <Redirect to={ROUTES.step1.form} />;
    }

    useEffect(() => {
        categories?.sort((c1, c2) => (c1.label[userLanguage] >= c2.label[userLanguage] ? 1 : -1));
    }, []);

    return (
        <form className="form-category-selection__container" method="post" onSubmit={handleSubmit}>
            {error && <div className="error">{error}</div>}
            {categories
                ?.map(({ id, label }) => (
                    <Field
                        key={id}
                        component={SelectableField}
                        name={id}
                        props={{ label: label[userLanguage] }}
                        type="checkbox"
                    />
                ))}
        </form>
    );
};

FormCategorySelection.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const selectedCategoryIds = state.user?.currentForm?.selectedDomains?.reduce((
            values,
            { professionalDomainId },
        ) => ({ ...values, [professionalDomainId]: true }), {});

        return {
            initialValues: selectedCategoryIds ?? {},
            enableReinitialize: true,
        };
    },
)(reduxForm({
    destroyOnUnmount: false,
    form: 'formCategorySelection',
    keepDirtyOnReinitialize: true,
})(FormCategorySelection));
