import PropTypes from 'prop-types';
import ButtonIcon from 'components/common/ButtonIcon';
import './style.scss';

const Button = ({
    handleClick,
    text,
}) => (
    <ButtonIcon onClick={handleClick}>
        <img alt="←" src="/assets/arrow-left.svg" />
        <span>{text}</span>
    </ButtonIcon>
);

Button.propTypes = {
    handleClick: PropTypes.func.isRequired,
    text: PropTypes.string,
};

export default Button;
