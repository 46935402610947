import { useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import LanguageContext from './LanguageContext';
import Account from 'routes/Account';
import Admin from 'routes/Admin';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import Dashboard from 'routes/Dashboard';
import DashboardCongrats from 'routes/Dashboard/Congrats';
import EndForm from 'routes/EndForm';
import Home from 'routes/Home';
import JobTasks from 'routes/JobTasks';
import LastResults from 'routes/LastResults';
import Confidentiality from 'routes/legal/Confidentiality';
import LegalNotices from 'routes/legal/LegalNotices';
import TermOfServices from 'routes/legal/TermOfServices';
import LoginRedirect from 'routes/LoginRedirect';
import NotFound from 'routes/NotFound';
import PasswordReset from 'routes/PasswordReset';
import Share from 'routes/Share/index';
import Step1 from 'routes/Steps/Step1';
import Step1CategorySelection from 'routes/Steps/Step1/CategorySelection';
import Step1Congrats from 'routes/Steps/Step1/Congrats';
import Step1Onboarding from 'routes/Steps/Step1/Onboarding';
import Step1Thanks from 'routes/Steps/Step1/Thanks';
import Step1Transition from 'routes/Steps/Step1/Transition';
import Step2 from 'routes/Steps/Step2';
import Step2Congrats from 'routes/Steps/Step2/Congrats';
import Step2Onboarding from 'routes/Steps/Step2/Onboarding';
import { ROUTES } from 'utils/constants';

const LocalizedRoutes = () => {
    const { language } = useContext(LanguageContext);

    const Routes = useMemo(() => (
        <Switch>
            {/* PUBLIC */}
            <Route component={Home} path={`/${language}`} exact />
            <Route component={LoginRedirect} path={`/${language}${ROUTES.loginRedirect}`} exact />
            <Route component={PasswordReset} path={`/${language}/password-reset/:token`} exact />
            <Route component={LegalNotices} path={`/${language}${ROUTES.legalnotices}`} exact />
            <Route component={Confidentiality} path={`/${language}${ROUTES.confidentiality}`} exact />
            <Route component={TermOfServices} path={`/${language}${ROUTES.tos}`} exact />
            <Route component={Share} path={`/${language}${ROUTES.share}`} exact />
            {/* ACCOUNT */}
            <AuthenticatedRoute component={Account} path={`/${language}${ROUTES.account}`} exact />
            <AuthenticatedRoute component={Admin} path={`/${language}${ROUTES.admin}`} exact />
            {/* STEP 1 */}
            <AuthenticatedRoute
                component={Step1Onboarding}
                path={`/${language}${ROUTES.step1.onboarding}`}
                exact
                isForm
            />
            <AuthenticatedRoute
                component={Step1CategorySelection}
                path={`/${language}${ROUTES.step1.categories}`}
                exact
                isForm
            />
            <AuthenticatedRoute component={Step1} path={`/${language}${ROUTES.step1.form}`} exact isForm />
            <AuthenticatedRoute
                component={Step1Transition}
                path={`/${language}${ROUTES.step1.transition}`}
                exact
                isForm
            />
            <AuthenticatedRoute component={Step1Congrats} path={`/${language}${ROUTES.step1.congrats}`} exact isForm />
            <AuthenticatedRoute component={Step1Thanks} path={`/${language}${ROUTES.step1.thanks}`} exact isForm />
            {/* STEP 2 */}
            <AuthenticatedRoute
                component={Step2Onboarding}
                path={`/${language}${ROUTES.step2.onboarding}`}
                exact
                isForm
            />
            <AuthenticatedRoute component={Step2} path={`/${language}${ROUTES.step2.form}`} exact isForm />
            <AuthenticatedRoute component={Step2Congrats} path={`/${language}${ROUTES.step2.congrats}`} exact isForm />
            {/* END FORM */}
            <AuthenticatedRoute component={EndForm} path={`/${language}${ROUTES.roti}`} exact />
            {/* DASHBOARD */}
            <AuthenticatedRoute
                component={DashboardCongrats}
                path={`/${language}${ROUTES.dashboard.main}${ROUTES.dashboard.congrats}`}
                exact
            />
            <AuthenticatedRoute
                component={Dashboard}
                path={`/${language}${ROUTES.dashboard.main}/:formId?`}
                exact
            />
            <AuthenticatedRoute
                component={JobTasks}
                path={`/${language}${ROUTES.dashboard.main}/:formId?/job/:jobId`}
                exact
            />
            <AuthenticatedRoute
                component={DashboardCongrats}
                path={`/${language}${ROUTES.dashboard.main}/:formId${ROUTES.dashboard.congrats}`}
                exact
            />
            <AuthenticatedRoute component={LastResults} path={`/${language}${ROUTES.lastResults}`} exact />
            {/* 404 PAGES */}
            <Route component={NotFound} path={`/${language}${ROUTES.notFound}`} status={404} exact />
            <Route component={NotFound} path={`/${language}/*`} status={404} exact />
        </Switch>
    ), [language]);

    return Routes;
};

export default LocalizedRoutes;
