import classnames from 'classnames';
import PropTypes from 'prop-types';
import Backdrop from 'components/common/Backdrop';
import './style.scss';

const Modal = ({ children, closeModal, fullscreen = false, title }) => (
    <Backdrop className="backdrop">
        <div className={classnames('modal', { 'modal--fullscreen': fullscreen })}>
            <div className="modal__inner">
                <button onClick={closeModal} type="button">
                    <img alt="close" className="modal__close" src="/assets/icons/close-black.svg" />
                </button>
                {title && (
                    <h1 className="modal__title">{title}</h1>
                )}
                {children}
            </div>
        </div>
    </Backdrop>
);

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    closeModal: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool,
    title: PropTypes.string,
};

export default Modal;
