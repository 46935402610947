import { toast } from 'react-toastify';

import Toast from 'components/common/Toast';

export const dismissToasts = () => {
    toast.dismiss();
};

export const toastSuccess = (title, description) => {
    dismissToasts();
    toast.success(
        <Toast
            description={description}
            icon="/assets/icons/toast-success.svg"
            title={title}
        />,
        { className: 'toast--success' },
    );
};
