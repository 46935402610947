import { SubmissionError } from 'redux-form';
import { SET_DISPLAYED_CURRENT_FORM_DASHBOARD } from 'actions/form';
import { GET, DELETE, PATCH } from 'utils/http';

export const CLEAN_USER = 'CLEAN_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const CLEAR_USER_CURRENT_CARD = 'CLEAR_USER_CURRENT_CARD';

export const getUser = (API_URL) => async (dispatch) => {
    try {
        const user = await GET(`${API_URL}/user`);
        await dispatch({ type: SET_USER, user });
        dispatch({ type: SET_DISPLAYED_CURRENT_FORM_DASHBOARD });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateUser = (API_URL, values) => async (dispatch) => {
    try {
        const user = await PATCH(`${API_URL}/user/`, { values });
        dispatch({ type: SET_USER, user });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateUserLanguage = (API_URL, values) => async (dispatch) => {
    try {
        await PATCH(`${API_URL}/user/language`, values);
        dispatch({ type: SET_USER_LANGUAGE, language: values.language });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const deleteAccount = (API_URL) => async () => {
    try {
        const response = await DELETE(`${API_URL}/user/`);

        if (response.success) {
            return true;
        }

        return null;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export const clearCurrentCard = () => ({
    type: CLEAR_USER_CURRENT_CARD,
});

export const cleanUser = () => ({
    type: CLEAN_USER,
});
