import {
    ADD_SELECTED_JOB,
    REMOVE_LAST_SELECTED_JOB,
    RESET_JOB,
    SET_JOB_OFFERS,
    SET_JOB,
    SET_NUMBER_JOBS_ANSWERED,
    SET_NUMBER_JOBS_SELECTED_DOMAIN,
    SET_SELECTED_JOBS,
    SET_SIMILAR_JOBS,
    SET_TOP5_JOBS,
} from 'actions/job';

const initialState = {
    currentJob: null,
    selectedJobs: [],
    top5: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case ADD_SELECTED_JOB:
            state.selectedJobs.push({ id: payload.selectedJob });

            return {
                ...state,
                selectedJobs: state.selectedJobs,
            };
        case SET_JOB:
            return {
                ...state,
                currentJob: payload.currentJob,
            };
        case SET_JOB_OFFERS:
            return {
                ...state,
                currentJob: { ...state.currentJob, jobOffers: payload.jobOffers },
            };
        case SET_SELECTED_JOBS:
            return { ...state, selectedJobs: payload.selectedJobs };
        case SET_TOP5_JOBS:
            return { ...state, top5: payload.top5 };
        case SET_SIMILAR_JOBS:
            return { ...state, similarJobs: payload.similarJobs };
        case SET_NUMBER_JOBS_SELECTED_DOMAIN:
            return { ...state, numberJobsSelectedDomain: payload.numberJobsSelectedDomain };
        case SET_NUMBER_JOBS_ANSWERED:
            return { ...state, numberJobsAnswered: payload.numberJobsAnswered };
        case RESET_JOB:
            return initialState;
        case REMOVE_LAST_SELECTED_JOB:
            state.selectedJobs.pop();

            return {
                ...state,
                selectedJobs: state.selectedJobs,
            };
        default:
            return state;
    }
};

export default reducer;
