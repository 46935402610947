import PropTypes from 'prop-types';
import './style.scss';

const Backdrop = ({ children, classNameParent, className, onClick, zIndex }) => (
    <div className={classNameParent}>
        <div
            aria-label="backdrop"
            className={className}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            style={{ zIndex }}
            tabIndex="0"
        />
        {children}
    </div>
);

Backdrop.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    classNameParent: PropTypes.string,
    onClick: PropTypes.func,
    zIndex: PropTypes.number,
};

export default Backdrop;
