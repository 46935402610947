import PropTypes from 'prop-types';
import './style.scss';

const HomeCard = ({ image, text, title }) => (
    <div className="home-card">
        <img alt="icon" className="home-card__img" src={image} />
        <div>
            <h3 className="home-card__title">{title}</h3>
            <p>{text}</p>
        </div>
    </div>
);

HomeCard.propTypes = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default HomeCard;
