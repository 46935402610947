import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';

import './style.scss';

const Congrats = ({ redirectTo, step, panelChildren }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Panel>{panelChildren}</Panel>
            <Main className="congrats__main">
                <h1><Trans i18nKey={`step${step}.congrats.title`} /></h1>
                <h2>{t(`step${step}.congrats.title.2`)}</h2>
                <img alt="congrats" src="/assets/congrats.svg" />
                <Link className="button button--black button--block center" to={redirectTo}>
                    {t(`step${step}.congrats.continue`)}
                </Link>
            </Main>
        </Container>
    );
};

Congrats.propTypes = {
    panelChildren: PropTypes.node.isRequired,
    redirectTo: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
};

export default Congrats;
