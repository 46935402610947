import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SubmissionError } from 'redux-form';

import { FunFact, ShortDescription, TitledSection } from 'components/Card';
import Skills from 'components/Card/Skills';
import Video from 'components/Card/Video';
import CardFeedback from 'components/CardFeedback';
import CardJob from 'components/CardJob';
import { useAppData } from 'hooks/useAppData';
import useUserLanguage from 'hooks/useUserLanguage';
import useWindowSize from 'hooks/useWindowSize';
import { formatLongTextAsHtml } from 'utils/helpers';
import { POST } from 'utils/http';

import './style.scss';

const DetailedCardJob = ({ bottomActionBar, job, onClose, topActionBar }) => {
    const { t } = useTranslation();
    const isMobile = 'xs' === useWindowSize().size;
    const userLanguage = useUserLanguage();
    const { API_URL } = useAppData();

    const {
        marketTrend,
        maxSalary,
        minSalary,
    } = job;

    const education = (job?.education && job.education[userLanguage]) ?? undefined;
    const funFact = (job?.funFact && job.funFact[userLanguage]) ?? undefined;
    const hardSkills = job?.hardSkills?.filter((hs) => hs.label && hs.label[userLanguage]);
    const longDescription = (job?.longDescription && job.longDescription[userLanguage]) ?? undefined;
    const professionalDomains = job?.professionalDomains?.filter((pd) => pd.label && pd.label[userLanguage]);
    const shortDescription = (job?.shortDescription && job.shortDescription[userLanguage]) ?? undefined;
    const softSkills = job?.softSkills?.filter((ss) => ss.label && ss.label[userLanguage]);
    const title = (job?.title && job.title[userLanguage]) ?? undefined;
    const video = (job?.video && job.video[userLanguage]) ?? '';

    const sendFeedback = async (values) => {
        try {
            await POST(`${API_URL}/jobs/${job.id}/feedback`, values);
        } catch (error) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

    return (
        <div className="detailed-card__wrapper">
            <div>
                <CardJob
                    bottomActionBar={bottomActionBar}
                    categories={professionalDomains}
                    className={classNames(
                        'detailed-card',
                        { 'detailed-card--desktop': !isMobile },
                    )}
                    onClick={onClose}
                    title={title}
                    topActionBar={topActionBar}
                >
                    {shortDescription && <ShortDescription>{shortDescription}</ShortDescription>}
                    {marketTrend === '1' && (
                        <div className="detailed-card__market-trend">
                            <img alt="Arrow up" src="/assets/icons/arrow-up.svg" />
                            <p>{t('cardsPage.marketTrend.trending')}</p>
                        </div>
                    )}
                    {longDescription && (
                        <TitledSection title={t('cardsPage.mission')} contentAsHtml>
                            {formatLongTextAsHtml(longDescription)}
                        </TitledSection>
                    )}
                    <TitledSection title={t('cardsPage.wages')}>
                        {`${minSalary}€ - ${maxSalary}€`}
                    </TitledSection>
                    <Skills skills={hardSkills}>{t('cardsPage.hardSkills')}</Skills>
                    <Skills skills={softSkills}>{t('cardsPage.softSkills')}</Skills>
                    {education && (
                        <TitledSection title={t('cardsPage.education')} contentAsHtml>
                            {formatLongTextAsHtml(education)}
                        </TitledSection>
                    )}
                    {funFact && <FunFact title={t('cardsPage.funFact')}>{funFact}</FunFact>}
                    <Video title={title} videoId={video} />
                </CardJob>
                <CardFeedback onSubmit={sendFeedback} />
            </div>
        </div>
    );
};

DetailedCardJob.propTypes = {
    bottomActionBar: PropTypes.node,
    job: PropTypes.shape({
        education: PropTypes.shape({}),
        funFact: PropTypes.shape({}),
        hardSkills: PropTypes.arrayOf(PropTypes.shape({})),
        id: PropTypes.string,
        longDescription: PropTypes.shape({}),
        marketTrend: PropTypes.string.isRequired,
        maxSalary: PropTypes.string,
        minSalary: PropTypes.string,
        professionalDomains: PropTypes.arrayOf(PropTypes.shape({})),
        shortDescription: PropTypes.shape({}),
        softSkills: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.shape({}),
        video: PropTypes.shape({}),
    }).isRequired,
    onClose: PropTypes.func,
    topActionBar: PropTypes.node,
};

export default DetailedCardJob;
