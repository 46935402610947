import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getTop5 } from 'actions';
import Spinner from 'components/common/Spinner';
import GoFurtherCard from 'components/Dashboard/GoFurtherCard';
import JobDashboard from 'components/Dashboard/JobDashboard';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionDashboard from 'components/StepDescription/DescriptionDashboard';
import { useAppData } from 'hooks/useAppData';
import useUserLanguage from 'hooks/useUserLanguage';
import { ROUTES } from 'utils/constants';

import './style.scss';

const Dashboard = ({ match }) => {
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    const { formId } = match.params;

    const {
        rotiFormId,
        lastCompletedFormId,
    } = useSelector((state) => state.user);
    const top5 = useSelector((state) => state.job.top5);

    useEffect(async () => {
        if(formId) {
            dispatch(getTop5(API_URL, formId, userLanguage, true));
        }
    }, []);

    if (!rotiFormId) {
        return <Redirect to={ROUTES.roti} />;
    }

    if (!formId) {
        if (lastCompletedFormId) {
            return <Redirect to={`${ROUTES.dashboard.main}/${lastCompletedFormId}`} />;
        }

        return <Redirect to={ROUTES.account} />;
    }

    const countCheckedTasks = (tasks) => (tasks ? Object.values(tasks).filter((val) => val).length : 0);

    return (
        <Container>
            <Panel>
                <DescriptionDashboard />
            </Panel>
            <Main className="main-dashboard__container" isLarge>
                {
                    top5.length === 0
                        ? <Spinner color="black" />
                        : (
                            <>
                                <h1>{t('dashboard.title', { count: top5.length })}</h1>
                                <span className="main-dashboard__directions">{t('dashboard.directions')}</span>
                                <div className="main-dashboard__cards-container">
                                    {top5.map((job) => (
                                        <JobDashboard
                                            key={job.id}
                                            checkedTasksCount={countCheckedTasks(job.sessionHistory.tasks)}
                                            formId={formId}
                                            jobId={job.id}
                                            jobOffersCount={job.jobOffers?.length ?? 0}
                                            jobTitle={job.title[userLanguage]}
                                            totalTasksCount={
                                                job?.sessionHistory?.tasks
                                                    ? Object.keys(job.sessionHistory.tasks).length
                                                    : 0
                                            }
                                        />
                                    ))}
                                </div>
                            </>
                        )
                }
                <h4>{t('dashboard.go.further')}</h4>
                <GoFurtherCard />
            </Main>
        </Container>
    );
};

Dashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string,
        }),
    }),
};

export default Dashboard;
