import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createForm, getUser } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionAccount from 'components/StepDescription/DescriptionAccount';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Account = () => {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state);
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();

    const onCreateForm = async () => {
        await dispatch(createForm(API_URL));
        push(ROUTES.step1.onboarding);
    };

    useEffect(() => {
        dispatch(getUser(API_URL));
    }, []);

    return (
        <Container bgColor="white">
            <Panel><DescriptionAccount /></Panel>
            <Main className="account__container">
                <h1 className="title account__title">{t('account.title')}</h1>
                <p><Trans i18nKey="account.text1" /></p>
                <p><Trans i18nKey="account.text2" /></p>
                <div className="main__buttons">
                    {user.currentFormId && (
                        <Button
                            className="button--outline"
                            onClick={() => push(ROUTES.step1.form)}
                            text={t('account.continue')}
                        />
                    )}
                    <Button
                        className="button--black"
                        onClick={onCreateForm}
                        text={t('account.start')}
                    />
                </div>
            </Main>
        </Container>
    );
};

export default Account;
