import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './style.scss';

const MoreInfo = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <button className="card-more-info" onClick={onClick} type="button">
            <span>{t('jobCard.moreInformations')}</span>
        </button>
    )
};

MoreInfo.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default MoreInfo;
