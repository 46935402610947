import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SimilarJobCard from 'components/SimilarJobCard';

import './style.scss';

const SimilarJobs = ({ similarJobs }) => {
    const { t } = useTranslation();

    return (
        <div className="card-similar-jobs">
            <h2>{t('similar.card.job.title')}</h2>
            <div className="card-similar-jobs__card">
                {similarJobs.map((job) => (
                    <SimilarJobCard key={job.id} job={job} />
                ))}
            </div>
        </div>
    );
};

SimilarJobs.propTypes = {
    similarJobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SimilarJobs;
