import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import JobOfferCard from 'components/JobOfferCard';

import './style.scss';

const JobOffers = ({ jobOffers }) => {
    const { t } = useTranslation();

    return (
        <div className="card-job-offers">
            <div className="card-job-offers__title">
                <img alt="jobOffers" src="/assets/icons/suitcase.svg" />
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t(
                        jobOffers === 1 ? 'jobTasks.jobOffers.title' : 'jobTasks.jobOffers.title_plural',
                        { count: jobOffers.length })
                    }}
                />
            </div>
            <div className="card-job-offers__cards">
                {jobOffers.map((offer) => (
                    <JobOfferCard key={offer.id} jobOffer={offer} />
                ))}
            </div>
        </div>
    );
};

JobOffers.propTypes = {
    jobOffers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default JobOffers;
