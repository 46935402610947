import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Backdrop from 'components/common/Backdrop';
import { useMenu } from 'hooks/useMenu';
import useWindowSize from 'hooks/useWindowSize';

import './style.scss';

const ConditionalWrapper = ({ condition, wrapper, children }) => {
    const isMobile = 'xs' === useWindowSize().size;

    return (
        condition && !isMobile ? wrapper(children) : children
    );
};

const Main = ({ className = '', children, isLarge }) => {
    const { menuIsOpen, openMenu } = useMenu();
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <ConditionalWrapper
            condition={menuIsOpen}
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            wrapper={(child) => (
                <Backdrop
                    className="container-main__backdrop"
                    classNameParent="container-main"
                    onClick={() => openMenu(!menuIsOpen)}
                    zIndex={499}
                >
                    {child}
                </Backdrop>
            )}
        >
            <main className={classnames('main__wrapper', { 'main--hidden': isMobile && menuIsOpen })}>
                <div className={classnames('main', className, { 'main--large': isLarge })}>{children}</div>
                <ToastContainer
                    autoClose={false}
                    draggable={false}
                    position={isMobile ? 'top-center' : 'bottom-center'}
                    rtl={false}
                />
            </main>
        </ConditionalWrapper>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isLarge: PropTypes.bool,
};

export default Main;
