import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSlider from 'react-slider';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import ChoiceField from 'components/common/form/ChoiceField';
import SelectField from 'components/common/form/SelectField';
import RotiQuestion from 'components/EndForm/RotiQuestion';
import {
    AGE_CAT,
    DIPLOMA_LIST,
    GENDER_LIST,
    JOBS_LIST,
    REASONS_LIST,
    ROTI_QUESTIONS,
    STATUS_LIST,
} from 'utils/constants';
import './style.scss';

const validate = (values) => {
    const errors = {
        reasons: {},
    };

    if (!values.gender) {
        errors.gender = 'Required';
    }
    if (!values.status) {
        errors.status = 'Required';
    }
    if (!values.diploma) {
        errors.diploma = 'Required';
    }
    if ((!values.reasons)
        || (values.reasons && (values.reasons && !Object.values(values.reasons).includes(true)))) {
        errors.reasons.knowledge = 'Required';
    }
    if (!values.satisfaction) {
        errors.satisfaction = 'Required';
    }
    if (!values.difficulty) {
        errors.difficulty = 'Required';
    }
    if (!values.aestheticism) {
        errors.aestheticism = 'Required';
    }
    if (!values.duration) {
        errors.duration = 'Required';
    }
    if (!values.content) {
        errors.content = 'Required';
    }

    return errors;
};

const FormRotiAndSocioDemo = ({ invalid, handleSubmit, submitting }) => {
    const { t } = useTranslation();

    const renderTooltip = (props, state) => (
        <div {...props}>
            <div className="slider-form__value">{AGE_CAT[state.valueNow].toString()}</div>
        </div>
    );

    return (
        <>
            <h1 className="title end-form__last-thing">{t('endForm.last_thing')}</h1>
            <div className="end-form__container">
                <h1>{t('endform.title')}</h1>
                <form method="post" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.age.title')}</p>
                        <Field
                            // TODO PCD: Refacto to delete eslint-disable
                            // eslint-disable-next-line react/no-unstable-nested-components
                            component={({ input: { onChange, value } }) => (
                                <ReactSlider
                                    className="slider-form"
                                    defaultValue={value}
                                    markClassName="slider-form__mark"
                                    max={5}
                                    min={0}
                                    onAfterChange={(v) => onChange(v)}
                                    renderThumb={(props, state) => renderTooltip(props, state)}
                                    thumbClassName="slider-form__thumb"
                                    trackClassName="slider-form__track"
                                />
                            )}
                            defaultValue="1"
                            name="ageRange"
                        />
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.gender.title')}</p>
                        <div className="choicefield__wrapper">
                            {GENDER_LIST.map((status) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    label={t(status.name)}
                                    name="gender"
                                    options={GENDER_LIST}
                                    type="radio"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.status.title')}</p>
                        <div className="choicefield__wrapper">
                            {STATUS_LIST.map((status) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    label={t(status.name)}
                                    name="status"
                                    type="radio"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.diploma.title')}</p>
                        <div className="choicefield__wrapper">
                            {DIPLOMA_LIST.map((status) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    label={t(status.name)}
                                    name="diploma"
                                    type="radio"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        {/* eslint-disable-next-line react/no-danger */}
                        <p dangerouslySetInnerHTML={{ __html: t('sociodemo.field.reason.title') }} className="label" />
                        <div className="choicefield__wrapper">
                            {REASONS_LIST.map((status) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    label={t(status.name)}
                                    name={`reasons.${status.key}`}
                                    type="checkbox"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.job.title')}</p>
                        <Field
                            component={SelectField}
                            name="job"
                            options={JOBS_LIST.map(({ key, name }) => ({ label: t(name), value: key }))}
                            placeholder={t('sociodemo.job.placeholder')}
                        />
                    </div>
                    <div className="end-form__roti-container">
                        <h2>{t('roti.title')}</h2>
                        {ROTI_QUESTIONS.map((rotiQuestion) => (
                            <RotiQuestion key={rotiQuestion} label={rotiQuestion} />
                        ))}
                    </div>
                    <Button
                        className="button--black base-margin end-form__submit-button"
                        disabled={invalid}
                        submitting={submitting}
                        text={t('endform.submit')}
                        submit
                    />
                </form>
            </div>
        </>
    );
};

FormRotiAndSocioDemo.propTypes = {
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.shape(),
    invalid: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
    destroyOnUnmount: false,
    form: 'formRotiAndSocioDemo',
    keepDirtyOnReinitialize: true,
    validate,
})(FormRotiAndSocioDemo);
