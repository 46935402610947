import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import '../Button/style.scss';

const Button = ({
    children = [],
    className = '',
    disabled = false,
    onClick,
    submit = false,
    submitting = false,
}) => (
    <button
        className={`button ${className}`}
        disabled={disabled || submitting}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
    >
        {
            submitting
                ? <Spinner />
                : children
        }
    </button>
);

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    submit: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default Button;
