import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import KeysGuide from 'components/KeysGuide';
import Container from 'components/layout/Container';
import Panel from 'components/layout/Panel';
import MainOnboarding from 'components/MainOnboarding';
import StepList from 'components/StepList';
import useWindowSize from 'hooks/useWindowSize';
import { ROUTES } from 'utils/constants';

const Step1Onboarding = () => {
    const { selectedDomains } = useSelector((state) => state.user?.currentForm);
    const isMobile = 'xs' === useWindowSize().size;

    if (selectedDomains?.length > 0) {
        return <Redirect to={ROUTES.step1.form} />;
    }

    return (
        <Container>
            <Panel>
                <StepList step={1} />
            </Panel>
            <MainOnboarding step={1} to={ROUTES.step1.categories}>
                {!isMobile && <KeysGuide />}
            </MainOnboarding>
        </Container>
    );
};

export default Step1Onboarding;
