import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAppData } from 'hooks/useAppData';

import './style.scss';

const stringifyLocation = (location) => {
    const { city, country } = location;

    if (city && country) {
        return `${city}, ${country}`;
    }

    if (!country) {
        return city;
    }

    return country;
};

const stringifyContract = (t, contract) => {
    let text = t(`jobTasks.jobOffers.contract.${contract.type}`);

    if (contract.duration_min || contract.duration_max) {
        if (contract.duration_min === contract.duration_max) {
            text += ` (${t('jobTasks.jobOffers.contract.duration', { duration: contract.duration_max })})`;
        } else {
            text += ` (${t('jobTasks.jobOffers.contract.period_duration', {
                min: contract.duration_min,
                max: contract.duration_max,
            })})`;
        }
    }

    return text;
};

const JobOfferCard = ({ jobOffer: { id, title, company, contract, location } }) => {
    const { t } = useTranslation();
    const { JT_JOB_ADS_SITE_URL } = useAppData();

    const onOpenOffer = () => {
        window.open(`${JT_JOB_ADS_SITE_URL}/${id}`, '_blank').focus();
    };

    return (
        <button
            className="job-offer-card"
            onClick={onOpenOffer}
            type="button"
        >
            <img alt={company.name} src={company.logo_url} />
            <div>
                <h4>{title}</h4>
                <div className="job-offer-card__company">{company.name}</div>
                <div className="job-offer-card__location">{stringifyLocation(location)}</div>
                <div className="job-offer-card__contract">{stringifyContract(t, contract)}</div>
            </div>
        </button>
    );
};

JobOfferCard.propTypes = {
    jobOffer: PropTypes.shape({
        company: PropTypes.shape({
            logo_url: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        contract: PropTypes.shape({
            duration_max: PropTypes.number,
            duration_min: PropTypes.number,
            type: PropTypes.string.isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        location: PropTypes.shape({
            city: PropTypes.string,
            country: PropTypes.string,
        }).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default JobOfferCard;
