import classnames from 'classnames';
import PropTypes from 'prop-types';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

const MainWithCard = ({ children, header, title, withSmallCard = false, withoutPadding = false }) => {
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <>
            <div className="main-card__header">{header}</div>
            {(!isMobile && title) && (
                <h1 className="title main-card__title">{title}</h1>
            )}
            <div className={
                classnames(
                    'main-card',
                    { 'main-card--small': withSmallCard, 'main-card--no-padding': withoutPadding },
                )
            }
            >
                {(isMobile && title) && (
                    <h1 className="title main-card__title">{title}</h1>
                )}
                {children}
            </div>
        </>
    );
};

MainWithCard.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node.isRequired,
    title: PropTypes.string,
    withoutPadding: PropTypes.bool,
    withSmallCard: PropTypes.bool,
};

export default MainWithCard;
