import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { PureComponent, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import createStore from './createStore';
import RouteChangeTracker from 'components/RouteChangeTracker';
import { AppDataContext } from 'hooks/useAppData';
import useBrowserLanguage from 'hooks/useBrowserLanguage';
import { MenuContext } from 'hooks/useMenu';
import { getUrlLanguage, getUrlWithoutLanguage } from 'hooks/useUrlParams';
import useUserLanguage from 'hooks/useUserLanguage';
import i18n from 'i18n';
import LanguageContext from 'Language/LanguageContext';
import Routes from 'Routes';
import { getAllLangs } from 'utils/lang';
import 'App.scss';

const LanguageProvider = ({ children }) => {
    const { pathname } = useLocation();
    const { push } = useHistory();
    const userLanguage = useUserLanguage();
    const browserLanguage = useBrowserLanguage();
    const urlLanguage = getUrlLanguage(pathname);

    const [language, setLanguage] = useState(userLanguage ?? urlLanguage ?? browserLanguage);
    const allowedLanguages = getAllLangs().filter(({ isAvailable }) => isAvailable).map(({ keyLang }) => keyLang);

    useEffect(() => {
        if (userLanguage) {
            setLanguage(userLanguage);
            if (allowedLanguages.includes(userLanguage) && pathname === '/missing-language') {
                push(`/${userLanguage}/account`);
            } else if (pathname !== '/') {
                push(`/${userLanguage}/${getUrlWithoutLanguage(language, pathname)}`);
            } else {
                push(`/${userLanguage}`);
            }
        }
    }, [userLanguage]);

    useEffect(() => {
        if (allowedLanguages.includes(language)) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        setLanguage(urlLanguage);
    }, [urlLanguage]);

    return (
        // TODO PCD: Use memo
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <LanguageContext.Provider value={{ language, setLanguage, allowedLanguages }}>
            {children}
        </LanguageContext.Provider>
    );
};

LanguageProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const MenuProvider = ({ children }) => {
    const [menuIsOpen, openMenu] = useState(false);

    return (
        // TODO PCD: Use memo
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <MenuContext.Provider value={{ menuIsOpen, openMenu }}>
            {children}
        </MenuContext.Provider>
    );
};
MenuProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();
        this.store = createStore({}, { history: this.history });

        // Disable fast-tap zoom on mobile
        let lastTouchEnd = 0;
        document.addEventListener('touchend', (event) => {
            const now = (new Date()).getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }

            lastTouchEnd = now;
        }, false);
    }

    render() {
        const { appData } = this.props;

        return (
            <AppDataContext.Provider value={appData}>
                <Provider store={this.store}>
                    <Router>
                        <RouteChangeTracker />
                        <LanguageProvider>
                            <MenuProvider>
                                <Routes />
                            </MenuProvider>
                        </LanguageProvider>
                    </Router>
                </Provider>
            </AppDataContext.Provider>
        );
    }
}

App.propTypes = {
    appData: PropTypes.shape({}).isRequired,
};

export default App;
