import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { checkToken, createForm, getUser, resetForm } from 'actions';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useAppData } from 'hooks/useAppData';
import { useMenu } from 'hooks/useMenu';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Menu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openMenu } = useMenu();
    const user = useSelector((state) => state.user);
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();
    const location = useLocation();

    useEffect(() => {
        async function fetchToken() {
            const result = await dispatch(checkToken());
            if (result?.success) {
                await dispatch(getUser(API_URL));
            }
        }
        if (!user) {
            fetchToken();
        }
    }, []);

    const onRestart = async () => {
        await dispatch(createForm(API_URL));
        push(ROUTES.step1.onboarding);
    };

    const onResume = async () => {
        await dispatch(resetForm());
        push(ROUTES.step1.onboarding);
    };

    const onDisplayLastDashboard = async () => {
        await dispatch(resetForm());
        push(`${ROUTES.dashboard.main}/${user.lastCompletedFormId}`);
    };

    return (
        <div className="menu">
            <h1 className="menu__title">{t('menu.title', { surname: user?.surname })}</h1>
            {user && <LanguageSwitcher color="black" />}
            <ul className="menu__inner">
                <li className="menu__link">
                    <Link
                        onClick={() => openMenu(false)}
                        to="/account"
                    >
                        <img alt="account" src="/assets/icons/house.svg" />
                        {t('menu.account')}
                    </Link>
                </li>
                {user && (
                    <>
                        {user.currentFormId && location.pathname.match('/dashboard/account') && (
                            <li className="menu__link">
                                <button
                                    onClick={() => { openMenu(false); onResume(); }}
                                    type="button"
                                >
                                    <img alt="resume" src="/assets/icons/pen.svg" />
                                    {t('menu.resume')}
                                </button>
                            </li>
                        )}
                        <li className="menu__link">
                            <button
                                onClick={() => { openMenu(false); onRestart(); }}
                                type="button"
                            >
                                <img alt="restart" src="/assets/icons/history.svg" />
                                {t('menu.restart')}
                            </button>
                        </li>
                        {user.lastCompletedFormId && !location.pathname.match('/dashboard') && (
                            <li className="menu__link">
                                <button
                                    onClick={() => { openMenu(false); onDisplayLastDashboard(); }}
                                    type="button"
                                >
                                    <img alt="results" src="/assets/icons/statistic.svg" />
                                    {t('menu.results')}
                                </button>
                            </li>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Menu;
