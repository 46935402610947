import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import ShareResultsModal from 'components/ShareResultsModal';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import LanguageContext from 'Language/LanguageContext';

const ShareModalButton = () => {
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const { language } = useContext(LanguageContext);
    const shareUrl = `${API_URL}/share/${language}/`;

    const onShare = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <ShareResultsModal
                    onClose={onClose}
                    shareUrl={shareUrl}
                />
            ),
        });
    };

    return (
        <Button
            className={`button--block shareButton no-padding ${isMobile ? 'button--outline' : ''}`}
            img="/assets/icons/share.svg"
            onClick={() => onShare()}
            text={t('results.share')}
        />
    );
};

export default ShareModalButton;
