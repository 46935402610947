import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Main from 'components/layout/Main';
import './style.scss';

const MainOnboarding = ({ children, step, to = '#' }) => {
    const { t } = useTranslation();

    return (
        <Main className="step__onboarding__main">
            <h2>{t(`step${step}.onboarding.stepNumber`)}</h2>
            <h1>{t(`step${step}.onboarding.title`)}</h1>
            <img alt="onboarding" src={`/assets/onboarding-step${step}.gif`} />
            {children}
            <Link className="button button--black button--block center" to={to}>
                {t('common.continue')}
            </Link>
        </Main>
    );
};

MainOnboarding.propTypes = {
    children: PropTypes.node,
    step: PropTypes.number.isRequired,
    to: PropTypes.string,
};

export default MainOnboarding;
