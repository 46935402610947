import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

export const Card = ({ children, className, containerClassName, id }) => (
    <div
        className={classnames('card-base__wrapper', containerClassName)}
        id={id}
    >
        <div className={classnames('card-base__content', className)}>
            {children}
        </div>
    </div>
);

Card.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    id: PropTypes.string,
};

export default Card;
