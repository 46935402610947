import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const Category = ({ className, color, text }) => (
    <h3 className={classnames('card-category', { [className]: className })} style={color && { color }}>
        #{text}
    </h3>
);

Category.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string.isRequired,
};

export default Category;
