import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Container from 'components/layout/Container';
import Panel from 'components/layout/Panel';
import MainOnboarding from 'components/MainOnboarding';
import StepList from 'components/StepList';
import { ROUTES } from 'utils/constants';

const Step2Onboarding = () => {
    const { step1Completed, step2Completed } = useSelector((state) => state.user?.currentForm);

    if (!step1Completed) {
        return <Redirect to={ROUTES.step1.onboarding} />;
    }

    if (step2Completed) {
        return <Redirect to={ROUTES.dashboard.main} />;
    }

    return (
        <Container>
            <Panel>
                <StepList step={2} />
            </Panel>
            <MainOnboarding step={2} to="/step/2" />
        </Container>
    );
};

export default Step2Onboarding;
