import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import './style.scss';

const Button = ({
    className = '',
    onClick,
    disabled = false,
    img = '',
    imgComponent,
    submit = false,
    submitting = false,
    text,
}) => (
    <button
        className={`button ${className}`}
        disabled={disabled || submitting}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
    >
        {
            submitting
                ? <Spinner />
                : (
                    <>
                        {imgComponent && imgComponent}
                        {img && <img alt="" src={img} />}
                        {text}
                    </>
                )
        }
    </button>
);

Button.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    img: PropTypes.string,
    imgComponent: PropTypes.element,
    onClick: PropTypes.func,
    submit: PropTypes.bool,
    submitting: PropTypes.bool,
    text: PropTypes.string,
};

export default Button;
