import PropTypes from 'prop-types';
import './style.scss';

const ShortDescription = ({ children }) => (
    <p className="card-short-description">{children}</p>
);

ShortDescription.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ShortDescription;
