import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSpring, animated, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import styles from './style.scss';
import useWindowSize from 'hooks/useWindowSize';

const defaultAvailableDirections = ['left', 'right'];

const SwipeableCard = forwardRef(({
    children,
    index,
    isPrevious,
    isSwipeable,
    onSwipe,
    availableDirections = defaultAvailableDirections,
}, ref) => {
    const { size, width, height } = useWindowSize();
    const isMobile = 'xs' === size;
    const outsideX = (width / 2) + 300;
    const outsideY = height + 150;

    const [{ x, y, rot }, set] = useSpring(() => (
        isPrevious ? { x: outsideX, y: 0, rot: outsideX } : { x: 0, y: 0, rot: 0 }
    ));

    useEffect(() => {
        set(isPrevious ? { x: outsideX, y: 0, rot: outsideX } : { x: 0, y: 0, rot: 0 });
    }, [outsideX, outsideY]);

    useImperativeHandle(ref, () => ({
        swipe: (direction) => {
            switch (direction) {
                case 'right':
                    set({ x: outsideX, y: 0, rot: outsideX });
                    break;
                case 'left':
                    set({ x: -outsideX, y: 0, rot: -outsideX });
                    break;
                case 'center':
                    set({ x: 0, y: 0, rot: 0 });
                    break;
                default:
                    break;
            }
        },
    }));

    const bind = useDrag(({
        down,
        movement: [mx, my],
        velocity,
    }) => {
        if (!isMobile || !isSwipeable) {
            return;
        }

        const trigger = velocity > 0.2;

        if (!down && trigger) {
            if (Math.abs(mx) > Math.abs(my)) {
                if (mx < 0 && availableDirections.indexOf('left') !== -1) {
                    set({ x: -outsideX, y: my, rot: -outsideX });
                    onSwipe('left');
                } else if (mx > 0 && availableDirections.indexOf('right') !== -1) {
                    set({ x: outsideX, y: my, rot: outsideX });
                    onSwipe('right');
                } else {
                    set({ x: 0, y: 0, rot: 0 });
                }
            } else {
                set({ x: 0, y: 0, rot: 0 });
            }
        } else {
            set({
                x: down ? mx : 0,
                y: down ? my : 0,
                rot: down ? mx : 0,
            });
        }
    });

    return (
        <animated.div
            className={styles.swipeable}
            style={{
                transform: interpolate(
                    [x, y, rot],
                    (newX, newY, newRot) => `rotate(${(newRot * 20) / outsideX}deg) translate3d(${newX}px,${newY}px,0)`,
                ),
                zIndex: 1000 - index * 100,
            }}
            {...bind()}
        >
            {children}
        </animated.div>
    );
});

SwipeableCard.displayName = 'SwipeableCard';

SwipeableCard.propTypes = {
    availableDirections: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    isPrevious: PropTypes.bool.isRequired,
    isSwipeable: PropTypes.bool.isRequired,
    onSwipe: PropTypes.func.isRequired,
};

export default SwipeableCard;
