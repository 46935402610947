import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';
import LanguageContext from 'Language/LanguageContext';

const Footer = () => {
    const { t } = useTranslation();
    const isMobile = 'xs' === useWindowSize().size;
    const { INNOVATION_SITE_URL } = useAppData();
    const { language } = useContext(LanguageContext);

    return (
        <footer className="home-footer" role="contentinfo">
            <div className="home-content__container">
                {isMobile && (
                    <>
                        <div className="home-footer__language-switcher">
                            <a href="https://www.jobteaser.com">
                                <img alt="Logo Job Teaser" src="/assets/jobteaser-logo-white.svg" width={149} />
                            </a>
                            <LanguageSwitcher className="language-switcher__small" />
                        </div>
                        <hr className="home-footer__divider" />
                    </>
                )}
                <div className="home-footer__links">
                    <div className="home-footer__links--left">
                        <a
                            className="home-footer__link"
                            href={INNOVATION_SITE_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            title={t('general.footer.innovation_site')}
                        >
                            {t('general.footer.innovation_site')}
                        </a>
                        <Link
                            className="home-footer__link"
                            title={t('general.legal_mentions')}
                            to={`/${language}/legalnotices`}
                        >
                            {t('general.legal_mentions')}
                        </Link>
                        <Link
                            className="home-footer__link"
                            title={t('general.terms_of_service')}
                            to={`/${language}/tos`}
                        >
                            {t('general.terms_of_service')}
                        </Link>
                        <Link
                            className="home-footer__link"
                            title={t('general.privacy_policy')}
                            to={`/${language}/confidentiality`}
                        >
                            {t('general.privacy_policy')}
                        </Link>
                    </div>
                    {
                        isMobile && <hr className="home-footer__divider" />
                    }
                    <div className="home-footer__copyright">
                        <span>{t('general.footer.copyright', { actualYear: new Date().getFullYear() })}</span>
                        {isMobile ? <br /> : ' - '}
                        <span>{t('general.footer.copyrightDetails')}</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
