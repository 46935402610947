import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUserLanguage } from 'actions';
import SelectField from 'components/common/form/SelectField';
import { useAppData } from 'hooks/useAppData';
import useUserLanguage from 'hooks/useUserLanguage';
import LanguageContext from 'Language/LanguageContext';
import { getAllLangs } from 'utils/lang';

import './style.scss';

const LanguageSwitcher = ({ className, color = 'white', label, shortLabel = false }) => {
    const { allowedLanguages, language, setLanguage } = useContext(LanguageContext);
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const history = useHistory();
    const languages = getAllLangs();
    const userLanguage = useUserLanguage();

    let defaultValue = language;
    if (userLanguage) {
        defaultValue = allowedLanguages.includes(userLanguage) ? userLanguage : undefined;
    }

    const onChangeLanguage = (newLanguage) => {
        if (userLanguage) {
            dispatch(updateUserLanguage(API_URL, { language: newLanguage }));
        } else {
            setLanguage(newLanguage);
            history.push(`/${newLanguage}`);
        }
    };

    // TODO: to be deleted when there are multiple allowed languages
    if (allowedLanguages.length === 1 && allowedLanguages[0] === userLanguage) {
        return null;
    }

    return (
        <div className={`${className} language-switcher language-switcher--${color}`}>
            <SelectField
                input={{
                    name: 'userLanguage',
                    onChange: (value) => onChangeLanguage(value),
                    value: defaultValue,
                }}
                label={label}
                labelHidden={!label}
                menuPlacement={shortLabel ? 'top' : 'bottom'}
                options={languages
                    .filter(({ isAvailable }) => isAvailable)
                    .map(({ keyLang, label: labelLng }) => ({
                        value: keyLang,
                        label: shortLabel ? keyLang.toUpperCase() : labelLng,
                    }))}
            />
        </div>
    );
};

LanguageSwitcher.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['black', 'white']),
    label: PropTypes.string,
    shortLabel: PropTypes.bool,
};

export default LanguageSwitcher;
