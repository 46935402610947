import PropTypes from 'prop-types';
import './style.scss';

const ProgressBar = ({ className = '', percentage }) => (
    <div className="progressBar">
        <div
            className={`progressBar__content ${className}`}
            style={
                {
                    width: percentage,
                }
            }
        />
    </div>
);

ProgressBar.propTypes = {
    className: PropTypes.string,
    percentage: PropTypes.string.isRequired,
};

export default ProgressBar;
