import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProgressBar from 'components/common/ProgressBar';
import './style.scss';
import { ROUTES } from 'utils/constants';

const JobDashboard = ({ checkedTasksCount, formId, jobId, jobOffersCount, jobTitle, totalTasksCount }) => {
    const { t } = useTranslation();
    const percentage = `${(checkedTasksCount / totalTasksCount) * 100}%`;

    return (
        <div className="job-dashboard__container">
            <h4>{jobTitle}</h4>
            <ProgressBar percentage={percentage} />
            <div className="job-dashboard__detail">
                <img alt="" className="job-dashboard__icon" src="/assets/icons/check.svg" />
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={
                        { __html: t('jobDashboard.taskCompleted', { count: checkedTasksCount }) }
                    }
                />
            </div>
            <div className="job-dashboard__detail">
                <img alt="jobOffers" className="job-dashboard__icon" src="/assets/icons/suitcase.svg" />
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={
                        { __html: t(
                            jobOffersCount === 1 ? 'jobDashboard.jobOffers' : 'jobDashboard.jobOffers_plural',
                            { count: jobOffersCount })
                        }
                    }
                />
            </div>
            <Link
                className="job-dashboard__link"
                to={`${ROUTES.dashboard.main}${formId ? `/${formId}` : ''}/job/${jobId}`}
            >
                {t('jobDashboard.goToChecklist')}
            </Link>
        </div>
    );
};

JobDashboard.propTypes = {
    checkedTasksCount: PropTypes.number.isRequired,
    formId: PropTypes.string,
    jobId: PropTypes.string.isRequired,
    jobOffersCount: PropTypes.number.isRequired,
    jobTitle: PropTypes.string.isRequired,
    totalTasksCount: PropTypes.number.isRequired,
};

export default JobDashboard;
