import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ShareModalButton from 'components/common/ShareModalButton';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

const GoFurtherCard = () => {
    const { t } = useTranslation();
    const { INNOVATION_SITE_URL } = useAppData();
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <div className="go-further-card__container">
            <img alt="" className="go-further-card__image" src="/assets/anticipation.svg" />
            <div className="go-further-card__content">
                <h2>{t('goFurtherCard.title')}</h2>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: t('goFurtherCard.description') }} />
                <Link
                    className="go-further-card__link"
                    target="_blank"
                    to={{ pathname: INNOVATION_SITE_URL }}
                >
                    {t('goFurtherCard.link')}
                </Link>
                {isMobile && (<ShareModalButton />)}
            </div>
        </div>
    );
};

export default GoFurtherCard;
