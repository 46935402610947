import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import ErrorPage from 'components/layout/ErrorPage';
import { DEFAULT_LANGUAGE_MISSING_PAGE } from 'utils/lang';

import './style.scss';

const MissingLanguage = () => {
    const { t } = useTranslation();

    return (
        <ErrorPage
            content={t('missingLanguage.content', { lng: DEFAULT_LANGUAGE_MISSING_PAGE })}
            title={t('missingLanguage.title', { lng: DEFAULT_LANGUAGE_MISSING_PAGE })}
        >
            <LanguageSwitcher label={t('missing_language.language', { lng: DEFAULT_LANGUAGE_MISSING_PAGE })} />
        </ErrorPage>
    );
};

export default MissingLanguage;
