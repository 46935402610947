import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import SelectField from 'components/common/form/SelectField';
import SimpleField from 'components/common/form/SimpleField';
import LanguageContext from 'Language/LanguageContext';
import ErrorType from 'types/ErrorType';
import { getAllLangs } from 'utils/lang';
import { required } from 'utils/validators';
import './style.scss';

const ExportForm = ({ customError, error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { allowedLanguages } = useContext(LanguageContext);

    return (
        <form className="base-margin export-form" method="post" onSubmit={handleSubmit}>
            <h2 className="title title--medium">{t('admin.export.title')}</h2>
            {error && <div className="error">{t(`admin.export.error.${error}`)}</div>}
            {customError && <div className="error">{t(`admin.export.error.${ErrorType[customError]}`)}</div>}
            <div className="export-form__dates">
                <Field
                    component={SimpleField}
                    label={t('admin.export.forms.date.start')}
                    name="dateStart"
                    type="date"
                    validate={required}
                />
                <Field
                    component={SimpleField}
                    label={t('admin.export.forms.date.end')}
                    name="dateEnd"
                    type="date"
                    validate={required}
                />
            </div>
            {/* TODO: to be deleted when there are multiple allowed languages */}
            {allowedLanguages.length > 1 && (
                <div className="export-form__language">
                    <Field
                        component={SelectField}
                        label={t('admin.export.forms.language')}
                        name="language"
                        options={getAllLangs()
                            .filter(({ isAvailable }) => isAvailable)
                            .map(({ keyLang, label }) => ({ value: keyLang, label }))}
                        hasAllOption
                    />
                </div>
            )}
            <p>/!\ 1000 formulaires maximum</p>
            <div className="export-form__button">
                <Button
                    className="button--block-mobile button--black"
                    disabled={!valid || submitting}
                    onClick={handleSubmit}
                    text={t('admin.button.export.forms')}
                    type="submit"
                />
            </div>
        </form>
    );
};

ExportForm.propTypes = {
    customError: PropTypes.string,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'ExportForm',
})(ExportForm);
