import { reset, SubmissionError } from 'redux-form';
import { RESET_CARDS } from 'actions/cards';
import { RESET_JOB } from 'actions/job';
import { GET, PATCH, POST } from 'utils/http';

export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';
export const SET_CURRENT_FORM_VALUES = 'SET_CURRENT_FORM_VALUES';
export const SET_DISPLAYED_CURRENT_FORM_DASHBOARD = 'SET_DISPLAYED_CURRENT_FORM_DASHBOARD';
export const SET_DISPLAYED_FORM_DASHBOARD = 'SET_DISPLAYED_FORM_DASHBOARD';
export const SET_LAST_COMPLETED_FORM_ID = 'SET_LAST_COMPLETED_FORM_ID';
export const SET_USER_CURRENT_FORM = 'SET_USER_CURRENT_FORM';

export const resetForm = () => (dispatch) => {
    dispatch({ type: RESET_JOB });
    dispatch({ type: RESET_CARDS });
};

export const createForm = (API_URL) => async (dispatch) => {
    try {
        const currentForm = await POST(`${API_URL}/form`);
        resetForm();
        dispatch(reset('formCategorySelection'));
        dispatch({ type: SET_CURRENT_FORM, currentForm });
        dispatch({ type: SET_USER_CURRENT_FORM, currentForm });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const setDisplayedFormDashboard = (API_URL, formId) => async (dispatch) => {
    if (formId) {
        try {
            const form = await GET(`${API_URL}/form/${formId}`);
            resetForm();
            dispatch({ type: SET_DISPLAYED_FORM_DASHBOARD, form });
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    } else {
        dispatch({ type: SET_DISPLAYED_CURRENT_FORM_DASHBOARD });
    }
};

export const updateCurrentForm = (API_URL, values) => async (dispatch) => {
    try {
        const data = await PATCH(`${API_URL}/form/current`, { values });
        dispatch({ type: SET_CURRENT_FORM_VALUES, ...data });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const completeStep1 = (API_URL) => async (dispatch) => {
    try {
        const data = await PATCH(`${API_URL}/form/complete-step-1`);
        dispatch({ type: SET_CURRENT_FORM_VALUES, ...data });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const saveTop5 = (API_URL, selection) => async (dispatch) => {
    try {
        const data = await POST(`${API_URL}/form/top5`, { selection });
        dispatch({ type: SET_CURRENT_FORM_VALUES, ...data });
        dispatch({ type: SET_LAST_COMPLETED_FORM_ID, id: data.id });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
