import PropTypes from 'prop-types';
import { useContext } from 'react';
import LanguageContext from '../../../Language/LanguageContext';
import './style.scss';

const Skills = ({ children, skills }) => {
    const { language } = useContext(LanguageContext);

    return (
        <div className="card-skills">
            <p className="strong margin-top--large">{children}</p>
            <div className="list-skills">
                {
                    skills.map(({ label, id }) => (
                        <div key={id} className="skill">{label[language]}</div>
                    ))
                }
            </div>
        </div>
    );
};

Skills.propTypes = {
    children: PropTypes.node.isRequired,
    skills: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.shape({}),
    })).isRequired,
};

export default Skills;
