import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';

import './style.scss';

const Share = () => {
    const { t } = useTranslation();

    return (
        <div className="share-img">
            <img
                alt="header-illustration"
                className="share-img__illustration"
                src="/assets/vasco-share.svg"
            />
            <div className="share-img__intro">
                <h1 className="share-img__title">
                    {t('home.header.title')}
                </h1>
                <Button
                    className="button button--blue"
                    text={t('share.button.title')}
                />
            </div>
        </div>
    );
};

export default Share;
