import {
    SET_CURRENT_FORM_VALUES,
    SET_CURRENT_FORM,
    SET_DISPLAYED_CURRENT_FORM_DASHBOARD,
    SET_DISPLAYED_FORM_DASHBOARD,
    SET_LAST_COMPLETED_FORM_ID,
    SET_USER_CURRENT_FORM,
} from 'actions/form';
import { SET_USER_ROTI_FORM_ID } from 'actions/roti';
import { CLEAN_USER, CLEAR_USER_CURRENT_CARD, SET_USER, SET_USER_LANGUAGE } from 'actions/user';

const initialState = null;

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_USER:
            return {
                ...state,
                ...payload.user,
            };
        case SET_USER_LANGUAGE:
            return {
                ...state,
                language: payload.language,
            };
        case SET_USER_ROTI_FORM_ID:
            return {
                ...state,
                rotiFormId: payload.rotiFormId,
            };
        case SET_CURRENT_FORM:
            return {
                ...state,
                currentForm: payload.currentForm,
            };
        case SET_USER_CURRENT_FORM:
            return {
                ...state,
                user: {
                    ...state.user,
                    currentFormId: payload.currentForm,
                },
            };
        case SET_CURRENT_FORM_VALUES:
            return {
                ...state,
                currentForm: {
                    ...state.currentForm,
                    ...payload,
                },
            };
        case SET_DISPLAYED_CURRENT_FORM_DASHBOARD:
            return {
                ...state,
                displayedFormDashboard: state.currentForm,
            };
        case SET_DISPLAYED_FORM_DASHBOARD:
            return {
                ...state,
                displayedFormDashboard: payload.form,
            };
        case SET_LAST_COMPLETED_FORM_ID:
            return {
                ...state,
                lastCompletedFormId: payload.id,
            };
        case CLEAR_USER_CURRENT_CARD:
            return {
                ...state,
                currentForm: {
                    ...state.currentForm,
                    currentCardId: null,
                },
            };
        case CLEAN_USER:
            return null;
        default:
            return state;
    }
};

export default reducer;
