import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getJobOffers, getJobWithTasks, getSimilarJobs, saveTasks, setJob } from 'actions';
import Button from 'components/common/Button';
import ProgressBar from 'components/common/ProgressBar';
import DetailedCardJob from 'components/DetailedCardJob';
import JobOffers from 'components/JobOffers';
import Container from 'components/layout/Container';
import MainWithBackButton from 'components/layout/MainWithBackButton';
import Panel from 'components/layout/Panel';
import SimilarJobs from 'components/SimilarJobs';
import DescriptionDashboard from 'components/StepDescription/DescriptionDashboard';
import { useAppData } from 'hooks/useAppData';
import useLocalizedHistoryPush from 'hooks/useLocalizedHistoryPush';
import useUserLanguage from 'hooks/useUserLanguage';
import { ROUTES } from 'utils/constants';

import './style.scss';

const JobTasks = ({ match }) => {
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    const push = useLocalizedHistoryPush();
    const { currentJob: job, similarJobs } = useSelector((state) => state.job);
    const top5 = useSelector((state) => state.job.top5);
    const { formId, jobId } = match.params;
    const tasks = {};
    const [showJobDetails, setShowJobDetails] = useState(false);

    useEffect(() => {
        const jobSelected = top5.find((j) => j.id === jobId);
        if (!jobSelected) {
            dispatch(getJobWithTasks(API_URL, jobId, formId));
            dispatch(getJobOffers(API_URL, jobId, userLanguage));
        } else {
            dispatch(setJob(jobSelected));
            dispatch(getJobOffers(API_URL, jobSelected.id, userLanguage));
        }

        dispatch(getSimilarJobs(API_URL, jobId));
    }, []);

    const sessionHistory = job?.sessionHistory;
    const jobTasks = sessionHistory ? sessionHistory.tasks : {};
    const [tasksValues, setTasksValues] = useState([]);

    useEffect(() => {
        setTasksValues([
            { id: 'task1', value: jobTasks?.task1 },
            { id: 'task2', value: jobTasks?.task2 },
            { id: 'task3', value: jobTasks?.task3 },
            { id: 'task4', value: jobTasks?.task4 },
            { id: 'task5', value: jobTasks?.task5 },
        ]);
    }, [job]);

    const onTaskSelect = (itemId) => {
        const newValues = [...tasksValues];
        const index = newValues.findIndex((item) => item.id === itemId);
        newValues[index].value = !newValues[index].value;
        setTasksValues(newValues);

        tasksValues.forEach(({ id, value }) => {
            tasks[id] = value;
        });

        dispatch(saveTasks(API_URL, jobId, tasks, formId)).then(({ areAllTasksCompleted }) => {
            if (areAllTasksCompleted) {
                push(`${ROUTES.dashboard.main}${formId ? `/${formId}` : ''}${ROUTES.dashboard.congrats}`);
            }
        });
    };

    const checkedTasksCount = tasksValues.filter((task) => task.value === true).length;
    const percentage = `${(checkedTasksCount / tasksValues.length) * 100}%`;

    return (
        <Container>
            <Panel>
                <DescriptionDashboard />
            </Panel>
            <MainWithBackButton className="job-tasks__main no-padding" to={`${ROUTES.dashboard.main}/${formId}`}>
                <div className="job-tasks__block">
                    {
                        showJobDetails && (
                            <DetailedCardJob
                                job={job}
                                onClose={() => setShowJobDetails(false)}
                            />
                        )
                    }
                    <div className="main-job-tasks__header">
                        <h1>{job?.title ? job.title[userLanguage] : ''}</h1>
                        <div className="main-job-tasks__tasks-completed">
                            <img alt="" className="job-dashboard__icon" src="/assets/icons/check.svg" />
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={
                                    { __html: t('jobDashboard.taskCompleted', { count: checkedTasksCount }) }
                                }
                            />
                        </div>
                        <div className="main-job-tasks__progressBar-container">
                            <ProgressBar percentage={percentage} />
                        </div>
                    </div>
                    <div className="main-job-tasks__container">
                        <h4>{t('jobTasks.directions')}</h4>
                        {tasksValues.map(({ id, value }, i) => (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label key={id}>
                                <img alt="" src={`/assets/icons/checkbox-${value ? '' : 'un'}checked.svg`} />
                                <span>{t(`jobTasks.task${i + 1}`)}</span>
                                <input
                                    defaultChecked={value}
                                    onChange={() => onTaskSelect(id)}
                                    type="checkbox"
                                />
                            </label>
                        ))}

                        <Button
                            className="button button--black"
                            onClick={() => {
                                setShowJobDetails(true);
                            }}
                            text={t('jobTasks.jobDetailsLink')}
                        />
                    </div>
                </div>

                {job?.jobOffers?.length > 0 && <JobOffers jobOffers={job.jobOffers} />}
                {similarJobs && similarJobs.length > 0 && <SimilarJobs similarJobs={similarJobs} />}
            </MainWithBackButton>
        </Container>
    );
};

JobTasks.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string,
            jobId: PropTypes.string.isRequired,
        }),
    }),
};

export default JobTasks;
