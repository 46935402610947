import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CustomButton from 'components/common/CustomButton';
import HeaderMenu from 'components/HeaderMenu';
import Menu from 'components/layout/Menu';
import SecondaryMenu from 'components/layout/SecondaryMenu';
import { useMenu } from 'hooks/useMenu';
import useWindowSize from 'hooks/useWindowSize';

import './style.scss';

const Panel = ({ children }) => {
    const { menuIsOpen, openMenu } = useMenu();
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <div className="container__panel">
            <div className={`panel ${menuIsOpen ? 'panel--open' : ''}`}>
                <header className="panel__header">
                    <HeaderMenu
                        leftElement={(
                            <Link to="/account">
                                <img alt="Logo" src="/assets/vasco-logo.svg" />
                            </Link>
                        )}
                        rightElement={(
                            <CustomButton
                                className="header-menu__login-button"
                                onClick={() => openMenu(!menuIsOpen)}
                            >
                                {
                                    menuIsOpen
                                        ? <img alt="close" src="/assets/icons/close.svg" />
                                        : <img alt="open" src="/assets/icons/burger.svg" />
                                }
                            </CustomButton>
                        )}
                    />
                </header>
                {(!isMobile && !menuIsOpen)
                    && (
                        <div className="panel__inner">
                            {children}
                        </div>
                    )}
                {menuIsOpen && <Menu />}
                {(!isMobile || menuIsOpen) && <SecondaryMenu />}
            </div>
        </div>
    );
};
Panel.propTypes = {
    children: PropTypes.node,
};
export default Panel;
