import { useEffect, useRef, useCallback } from 'react';

const defaultKeyToDirection = {
    ArrowRight: 'right',
    ArrowLeft: 'left',
};

const defaultAvailableDirections = ['left', 'right'];

const useSwipe = ({
    onSwipe,
    onReset,
    availableDirections = defaultAvailableDirections,
    isKeyboardAvailable = false,
    keyToDirection = defaultKeyToDirection,
    canReset = false,
    resetKey = ' ',
    compareWithPrevious = true, // to know if we must compare card refs between two swipes
}) => {
    const locked = useRef(false);
    const cardRef = useRef(null);
    // save the card ref we've already swiped forward (left | right direction)
    const usedForwardCardRef = useRef(null);
    // save the card ref we've already swiped backward (down direction)
    const usedBackwardCardRef = useRef(null);

    const swipeCurrentCard = useCallback(async (direction) => {
        if (
            !locked.current
            && cardRef.current
            && (!compareWithPrevious || cardRef.current !== usedForwardCardRef.current)
        ) {
            locked.current = true;
            await cardRef.current.swipe(direction);
            await onSwipe(direction);
            usedForwardCardRef.current = cardRef.current;
            locked.current = false;
        }
    }, [onSwipe]);

    const resetPreviousCard = useCallback(async () => {
        if (
            !locked.current
            && cardRef.current
            && (!compareWithPrevious || cardRef.current !== usedBackwardCardRef.current)
        ) {
            locked.current = true;
            await onReset();
            usedBackwardCardRef.current = cardRef.current;
            locked.current = false;
        }
    }, [onReset]);

    const handleKeyPress = useCallback(async ({ key, repeat }) => {
        if (locked.current) {
            return;
        }
        const direction = keyToDirection[key];

        if (!repeat && direction && availableDirections.indexOf(direction) !== -1) {
            await swipeCurrentCard(direction);
        } else if (!repeat && key === resetKey && canReset) {
            await resetPreviousCard();
        }
    }, [keyToDirection, availableDirections, swipeCurrentCard]);

    const generateCardProps = useCallback((previousCardId, currentCardId, id, index) => {
        let props = {
            key: id,
            index,
            isPrevious: false,
            onSwipe,
            availableDirections,
        };

        if (currentCardId === id) {
            props = { ...props, ref: cardRef };
        } else if (previousCardId === id) {
            props = { ...props, isPrevious: true, ref: cardRef };
        }

        return props;
    }, [onSwipe]);

    useEffect(() => {
        if (isKeyboardAvailable) {
            window.addEventListener('keydown', handleKeyPress);
        } else {
            window.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isKeyboardAvailable, handleKeyPress]);

    return {
        generateCardProps,
        swipeCurrentCard,
        resetPreviousCard,
    };
};

export default useSwipe;
