import downloadjs from 'downloadjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import ExportForm from 'components/Admin/ExportForm';
import Button from 'components/common/Button';
import SelectField from 'components/common/form/SelectField';
import Spinner from 'components/common/Spinner';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import DescriptionAdmin from 'components/StepDescription/DescriptionAdmin';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';
import ErrorType from 'types/ErrorType';
import { USER_ROLES } from 'utils/constants';
import { GET } from 'utils/http';
import { getAllLangs } from 'utils/lang';
import './style.scss';

const Admin = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const history = useHistory();
    const [stats, setStats] = useState(null);
    const [statsLanguage, setStatsLanguage] = useState('');
    const { allowedLanguages } = useContext(LanguageContext);
    const isAdmin = useSelector(({ auth }) => auth.status === USER_ROLES.ADMIN);
    const [error, setError] = useState(undefined);

    if (!isAdmin) {
        return <Redirect to="/account" />;
    }

    useEffect(async () => {
        const response = await GET(`${API_URL}/admin/stats${statsLanguage ? `?language=${statsLanguage}` : ''}`);
        setStats(response);
    }, [statsLanguage]);

    const exportForms = async ({ dateStart, dateEnd, language }) => {
        try {
            const queryParams = `?dateStart=${dateStart}&dateEnd=${dateEnd}${language ? `&language=${language}` : ''}`;
            const response = await GET(
                `${API_URL}/admin/export/forms${queryParams}`,
                null,
                { jsonify: false },
            );
            if (!response) {
                throw new SubmissionError({ _error: 'error.server.unknown' });
            }
            const fileName = `export-vasco-forms_${Date.now()}.csv`;
            const file = await response.blob();

            return downloadjs(file, fileName);
        } catch (err) {
            const { code } = await err.response.json();
            switch (code) {
                case ErrorType.TOO_MANY_RESULTS:
                    setError(ErrorType.TOO_MANY_RESULTS);
                    break;
                case ErrorType.START_END_DATES:
                    setError(ErrorType.START_END_DATES);
                    break;
                case 500:
                default:
                    setError(ErrorType.INTERNAL_ERROR);
            }

            return null;
        }
    };

    if (!stats) {
        return null;
    }

    return (
        <Container bgColor="primary">
            <Panel><DescriptionAdmin /></Panel>
            <Main className="admin" isLarge>
                <MainWithCard
                    header={(
                        <Button
                            className="button--transparent admin__back-button"
                            img="/assets/icons/white-back.svg"
                            onClick={() => history.goBack()}
                            text={t('common.back')}
                        />
                    )}
                >
                    <h1 className="admin__title">{t('admin.title')}</h1>
                    {stats ? (
                        <>
                            {/* TODO: to be deleted when there are multiple allowed languages */}
                            {allowedLanguages.length > 1 && (
                                <SelectField
                                    input={{
                                        name: 'statLanguage',
                                        onChange: (value) => setStatsLanguage(value),
                                        value: statsLanguage,
                                    }}
                                    label={t('admin.stat.language')}
                                    options={getAllLangs()
                                        .filter(({ isAvailable }) => isAvailable)
                                        .map(({ keyLang, label }) => ({ value: keyLang, label }))}
                                    hasAllOption
                                />
                            )}
                            <ul className="admin__list">
                                <li>{`${t('admin.stat.nbUser')}${stats.nbUser}`}</li>
                                <li>{`${t('admin.stat.nbUserLastSevenDays')}${stats.nbUserLastSevenDays}`}</li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfSelectedDomainsBeforeInterstitial')}
                                    ${stats.averageNumberOfSelectedDomainsBeforeInterstitial?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfSelectedDomains')}
                                    ${stats.averageNumberOfSelectedDomains?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfLikedJobs')}
                                    ${stats.averageNumberOfLikedJobs?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfDislikedJobs')}
                                    ${stats.averageNumberOfDislikedJobs?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.medianOfLikedJobs')}${stats.medianOfLikedJobs?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.medianOfDislikedJobs')}
                                    ${stats.medianOfDislikedJobs?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageRotiDuration')}
                                    ${stats.averageRotiDuration?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageRotiSatisfaction')}
                                    ${stats.averageRotiSatisfaction?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageRotiDifficulty')}
                                    ${stats.averageRotiDifficulty?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageRotiAestheticism')}
                            ${stats.averageRotiAestheticism?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageRotiContent')}
                                    ${stats.averageRotiContent?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.percentageOfFormsAllJobsOfSelectedDomainsSeen')}
                                    ${stats.percentageOfFormsAllJobsOfSelectedDomainsSeen?.toFixed(2) ?? 0}%`}
                                </li>
                                <li>
                                    {`${t('admin.stat.percentageOfFormsAllJobsSeen')}
                                    ${stats.percentageOfFormsAllJobsSeen?.toFixed(2) ?? 0}%`}
                                </li>
                            </ul>
                            <ExportForm customError={error} onSubmit={exportForms} />
                        </>
                    ) : (
                        <div className="admin__spinner">
                            <Spinner color="black" />
                        </div>
                    )}
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default Admin;
