import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MainEndForm from 'components/EndForm/MainEndForm';
import Container from 'components/layout/Container';
import Panel from 'components/layout/Panel';
import { ROUTES } from 'utils/constants';

import './style.scss';

const EndForm = () => {
    const { t } = useTranslation();
    const { rotiFormId } = useSelector(({ user }) => user);

    if (rotiFormId) {
        return <Redirect to={ROUTES.dashboard} />;
    }

    return (
        <Container bgColor="primary">
            <Panel>
                <h1 className="endForm-panel__title">{t('endForm.panel.title')}</h1>
                <p className="endForm-panel__description"><Trans i18nKey="endForm.panel.description" /></p>
            </Panel>
            <MainEndForm />
        </Container>
    );
};

export default EndForm;
