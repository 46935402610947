import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';

import Backdrop from 'components/common/Backdrop';
import Button from 'components/common/Button';
import ChoiceField from 'components/common/form/ChoiceField';
import TextAreaField from 'components/common/form/TextAreaField';
import { FEEDBACK_PART, FEEDBACK_TYPE } from 'utils/constants';
import { required } from 'utils/validators';

import './style.scss';

const validate = (values) => {
    const errors = { parts: {} };

    if ((!values.parts)
        || (values.parts && (values.parts && !Object.values(values.parts).includes(true)))) {
        errors.parts.other = 'Required';
    }

    return errors;
};

const CardFeedback = ({ handleSubmit, submitting, valid }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isSend, setIsSend] = useState(false);

    const submit = () => {
        handleSubmit();
        setIsSend(true);
        dispatch(reset('formFeedback'));
    };

    const onClose = () => {
        setIsSend(false);
        setOpen(false);
    };

    return (
        <div className="feedback">
            {open ? (
                <Backdrop className="backdrop">
                    <div className="feedback__form">
                        <div className="feedback__form__content">
                            <div className="feedback__header">
                                {!isSend && (
                                    <h1 className="feedback__header__title">
                                        {t('cardsPage.feedback.title')}
                                    </h1>
                                )}
                                <button
                                    className="feedback__header__close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <img
                                        alt={t('common.close')}
                                        src="/assets/icons/close-black.svg"
                                    />
                                </button>
                            </div>
                            {!isSend ? (
                                <form className="margin-top--large" onSubmit={submit}>
                                    <div className="form-group">
                                        <p className="label">{t('cardsPage.feedback.type')}</p>
                                        <Field component="select" name="type">
                                            {FEEDBACK_TYPE.map((type) => (
                                                <option
                                                    key={type}
                                                    value={type}
                                                >
                                                    {t(`cardsPage.feedback.type.${type}`)}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="form-group">
                                        <p className="label">{t('cardsPage.feedback.part')}</p>
                                        <div className="choicefield__wrapper">
                                            {FEEDBACK_PART.map((part) => (
                                                <Field
                                                    key={part}
                                                    component={ChoiceField}
                                                    label={t(`cardsPage.feedback.part.${part}`)}
                                                    name={`parts.${part}`}
                                                    type="checkbox"
                                                    value={t(`cardsPage.feedback.part.${part}`)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <Field
                                        component={TextAreaField}
                                        label={t('cardsPage.feedback.details')}
                                        name="feedback"
                                        placeholder="Text"
                                        type="text"
                                        validate={[required]}
                                    />
                                    <div className="feedback__form__button base-margin">
                                        <Button
                                            className="button--grey"
                                            onClick={onClose}
                                            text={t('cardsPage.feedback.cancel')}
                                        />
                                        <Button
                                            className="button--black"
                                            disabled={!valid}
                                            submitting={submitting}
                                            text={t('cardsPage.feedback.send')}
                                            submit
                                        />
                                    </div>
                                </form>
                            ) : (
                                <div className="feedback__thanks">
                                    <img alt="thanks-for-comments" src="/assets/feedback.svg" />
                                    <h1 className="feedback__title">
                                        {t('cardsPage.feedback.thanks.title')}
                                    </h1>
                                    <h2>{t('cardsPage.feedback.thanks.subtitle')}</h2>
                                    <Button
                                        className="button--black base-margin"
                                        onClick={onClose}
                                        text={t('cardsPage.feedback.thanks.button')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Backdrop>
            ) : (
                <Button
                    className="button--black feedback__button"
                    img="/assets/icons/pen.svg"
                    onClick={() => setOpen(!open)}
                    text={t('cardsPage.feedback.button')}
                />
            )}
        </div>
    );
};

CardFeedback.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default (reduxForm({ form: 'formFeedback', validate })(CardFeedback));
