import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import SimpleField from 'components/common/form/SimpleField';
import { useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

const PasswordLostForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">
                {t('auth.lostPassword.title')}
            </h1>
            <p className="subtitle">{t('auth.lostPassword.subtitle')}</p>
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Button
                    className="button--block button--black base-margin"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('auth.lostPassword.submit')}
                    submit
                />
                <div className="base-margin">
                    <button
                        className="link text--medium"
                        onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                        type="button"
                    >
                        {t('auth.lostPassword.switchToLogin')}
                    </button>
                </div>
            </form>
        </>
    );
};

PasswordLostForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'PasswordLostForm',
})(PasswordLostForm);
