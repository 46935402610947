import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { completeStep1, createForm, getNumberJobsAnswered, updateCurrentForm } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionBreak from 'components/StepDescription/DescriptionBreak';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { ROUTES } from 'utils/constants';

import './style.scss';

const Step1Thanks = () => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const numberJobsAnswered = useSelector((state) => state.job.numberJobsAnswered);
    const areAllCategoriesSelected = useSelector((state) => state.cards.discovery.areAllCategoriesSelected);
    const { step1Completed, step2Completed, selectedDomains } = useSelector((state) => state.user?.currentForm);

    useEffect(() => {
        if (selectedDomains && !numberJobsAnswered) {
            dispatch(getNumberJobsAnswered(API_URL));
        }

        if (areAllCategoriesSelected) {
            dispatch(completeStep1(API_URL));
        }
    }, []);

    if (selectedDomains?.length === 0) {
        return <Redirect to={ROUTES.step1.categories} />;
    }

    if (step1Completed && !areAllCategoriesSelected) {
        return <Redirect to={ROUTES.step2.onboarding} />;
    }

    if (step2Completed) {
        return <Redirect to={ROUTES.dashboard.main} />;
    }

    const restartForm = async () => {
        await dispatch(createForm(API_URL));
        push(ROUTES.step1.onboarding);
    };

    const continueForm = async () => {
        await dispatch(updateCurrentForm(API_URL, { isPassedInterstitial: true }));
        push(ROUTES.step1.categories);
    };

    return (
        <Container>
            <Panel><DescriptionBreak /></Panel>
            <Main className="step1-thanks__main">
                <h1>{t('step1.thanks.title')}</h1>
                <h2><Trans count={numberJobsAnswered} i18nKey="step1.thanks.title.2" /></h2>
                <Button
                    className="button--black button--block-mobile center"
                    onClick={areAllCategoriesSelected ? restartForm : continueForm}
                    text={t('step1.thanks.restart')}
                />
            </Main>
        </Container>
    );
};

export default Step1Thanks;
