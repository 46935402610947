import PropTypes from 'prop-types';
import './style.scss';

const FunFact = ({ children, title }) => (
    <div className="card-fun-fact">
        <div className="flex-row">
            <img alt="fun-fact" src="/assets/icons/fun-fact.svg" />
            <p className="strong">{title}</p>
        </div>
        <span>{children}</span>
    </div>
);

FunFact.propTypes = {
    children: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default FunFact;
