import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { completeStep1, getSelectedJobs, getUser } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import MainWithStep from 'components/layout/MainWithStep';
import Panel from 'components/layout/Panel';
import StepList from 'components/StepList';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import useWindowSize from 'hooks/useWindowSize';
import JobSelection from 'routes/Steps/Step1/JobSelection';
import { ROUTES } from 'utils/constants';

import './style.scss';

const Step1 = () => {
    const {
        currentForm: { step1Completed, selectedDomains, isPassedInterstitial },
    } = useSelector((state) => state.user);
    const selectedJobs = useSelector((state) => state.job.selectedJobs);
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const dispatch = useDispatch();
    const { API_URL } = useAppData();

    useEffect(() => {
        if (selectedDomains) {
            dispatch(getSelectedJobs(API_URL));
        }

        dispatch(getUser(API_URL));
    }, []);

    if (!selectedDomains) {
        return null;
    }

    if (selectedDomains.length === 0) {
        return <Redirect to={ROUTES.step1.categories} />;
    }

    if (step1Completed) {
        return <Redirect to={ROUTES.step2.onboarding} />;
    }

    const stopExploration = async () => {
        if (selectedJobs?.length > 0) {
            dispatch(completeStep1(API_URL)).then(() => push(ROUTES.step1.congrats));
        } else {
            push(ROUTES.step1.thanks);
        }
    };

    return (
        <Container className="step1__container">
            <Panel>
                <StepList step={1}>
                    {isPassedInterstitial && selectedJobs?.length > 0 && (
                        <Button
                            className="button--block button--blue base-margin"
                            onClick={stopExploration}
                            text={t('step1.menu.stopExploration')}
                        />
                    )}
                </StepList>
            </Panel>
            <MainWithStep
                subheader={t('step1.onboarding.stepNumber')}
                title={t('step1.onboarding.title')}
            >
                <JobSelection handleStopExploration={stopExploration} />
                {isMobile && isPassedInterstitial && selectedJobs?.length > 0 && (
                    <div className="stopExploration">
                        <Button
                            className="button--block button--black"
                            onClick={stopExploration}
                            text={t('step1.menu.stopExploration')}
                        />
                    </div>
                )}
            </MainWithStep>
        </Container>
    );
};

export default Step1;
