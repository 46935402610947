import { SubmissionError } from 'redux-form';
import { POST } from 'utils/http';

export const SET_USER_ROTI_FORM_ID = 'SET_USER_ROTI_FORM_ID';

export const createRoti = (API_URL, values) => async (dispatch) => {
    try {
        const rotiFormId = await POST(`${API_URL}/roti/`, { values });
        dispatch({ type: SET_USER_ROTI_FORM_ID, rotiFormId });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
