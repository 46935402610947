import Base from '../CardBase';
import Mock from 'components/Mock';

import './style.scss';

const CardMock = () => (
    <Base containerClassName="card-job-mock__card-container">
        <div className="flex-row">
            <Mock className="margin-right--x-small" height="2rem" width="10rem" />
            <Mock height="2rem" width="10rem" />
        </div>
        <Mock className="card-job-mock__title" width="25rem" />
        <hr className="card-job-mock__divider" />
        <Mock height="10rem" width="100%" />
        <Mock className="card-job-mock__info-container" height="3.8rem" width="11rem" />
    </Base>
);
export default CardMock;
