import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Category, MoreInfo } from 'components/Card';
import useUserLanguage from 'hooks/useUserLanguage';
import './style.scss';

const CardJob = ({
    bottomActionBar = null,
    categories,
    children,
    className = '',
    onBack,
    onClick,
    onMoreInfo = null,
    previousCardId,
    title = '',
    topActionBar = null,
}) => {
    const userLanguage = useUserLanguage();

    return (
        <Card containerClassName={classNames('card-job__card-container', className)}>
            <div className="card-job__domains">
                {
                    categories && categories
                        .filter(({ hashtagLabel }) => hashtagLabel && hashtagLabel[userLanguage])
                        .slice(0, 2)
                        .map(({ id, hashtagLabel }) => (
                            <Category key={id} className="card-job__domain" text={hashtagLabel[userLanguage]} />
                        ))
                }
            </div>
            {!onMoreInfo && (
                <button className="button-close" onClick={onClick} type="button">
                    <img alt="close" src="/assets/icons/close-black.svg" />
                </button>
            )}
            <h1 className="card-job__title">{title}</h1>
            {topActionBar || <hr className={onMoreInfo ? 'card-job__divider--black' : 'card-job__divider'} />}
            {children}
            {
                onMoreInfo && (
                    <div className="card-job__info-container">
                        <MoreInfo onClick={() => {
                            onMoreInfo();
                        }}
                        />
                    </div>
                )
            }
            {bottomActionBar && !onMoreInfo && (
                <hr className="card-job__divider" />
            )}
            {bottomActionBar}
            {onMoreInfo && previousCardId && (
                <button className="go-back" onClick={onBack} type="button">
                    <img alt="back" src="/assets/icons/go-back.svg" />
                </button>
            )}
        </Card>
    );
};

CardJob.propTypes = {
    bottomActionBar: PropTypes.node,
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    onBack: PropTypes.func,
    onClick: PropTypes.func,
    onMoreInfo: PropTypes.func,
    previousCardId: PropTypes.string,
    title: PropTypes.string,
    topActionBar: PropTypes.node,
};

export default CardJob;
