import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const SelectableField = ({ input, label, disabled = false, withoutBorder = false }) => (
    <>
        <input className="selectable-field__input" disabled={disabled} id={input.name} type="checkbox" {...input} />
        <label
            className={classnames(
                'selectable-field__label',
                { 'selectable-field__label-without-border': withoutBorder },
            )}
            htmlFor={input.name}
        >
            <img
                alt="icon"
                src={input.value ? '/assets/icons/checkbox-checked.svg' : '/assets/icons/checkbox-unchecked.svg'}
            />
            {label}
        </label>
    </>
);

SelectableField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
    }).isRequired,
    label: PropTypes.string.isRequired,
    withoutBorder: PropTypes.bool,
};

export default SelectableField;
