import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const Mock = ({ className = '', height, width }) => {
    const style = {};

    if (height) {
        style.height = height;
    }

    if (width) {
        style.width = width;
    }

    return (
        <div className={classnames('mock', className)} style={style} />
    );
};

Mock.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Mock;
