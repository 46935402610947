import { SubmissionError } from 'redux-form';
import ActionType from 'types/ActionType';
import { POST } from 'utils/http';

export const SET_CARDS = 'SET_CARDS';
export const RESET_CARDS = 'RESET_CARDS';

/**
 * Fetch next card or first card or previous card
 *
 * @param {String} API_URL url of the back
 * @param {String | undefined} currentDisplayedCardId id of the job on the current card ;
 *      undefined if this is the first call
 * @param {ActionType | undefined} action last user action ;
 *      undefined if this is the first call
 */
export const getNextCard = (API_URL, currentDisplayedCardId, action) => async (dispatch, getState) => {
    try {
        let nextCard = null;
        const body = {};

        // if this is not the first call
        if (currentDisplayedCardId) {
            body.currentCardId = currentDisplayedCardId;

            if (action) {
                // switch to the next card according to what we got from last call (ie what's in the store)...
                const { cards: { discovery: { cardIfLike, cardIfDislike } } } = getState();
                nextCard = action === ActionType.ACTION_TYPE_LIKE ? cardIfLike : cardIfDislike;

                // ...and set the user's action
                body.action = action;
                body.nextCardId = nextCard.id;
            }
        }

        // then call the API for the next set of cards
        const data = await POST(`${API_URL}/cards/next`, body);

        const dispatchAction = {
            type: SET_CARDS,
            current: data.currentCard,
            cardIfLike: data.cardIfLike,
            cardIfDislike: data.cardIfDislike,
            goalReached: data.goalReached,
            isExplorationOver: data.isExplorationOver,
            areAllCategoriesSelected: data.areAllCategoriesSelected,
            previous: data.previousCard,
        };

        dispatch(dispatchAction);
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

/**
 * Fetch previous card
 *
 * @param {String} API_URL url of the back
 * @param {String | undefined} prevDisplayedCardId id of the job on the previous card ;
 *      undefined if this is the first call
 */
export const getPreviousCard = (API_URL, prevDisplayedCardId) => async (dispatch) => {
    try {
        // then call the API for the next set of cards
        const data = await POST(`${API_URL}/cards/previous`, { currentCardId: prevDisplayedCardId });

        const dispatchAction = {
            type: SET_CARDS,
            current: data.currentCard,
            cardIfLike: data.cardIfLike,
            cardIfDislike: data.cardIfDislike,
            goalReached: data.goalReached,
            areAllCategoriesSelected: data.areAllCategoriesSelected,
            isExplorationOver: data.isExplorationOver,
            previous: null,
        };

        dispatch(dispatchAction);
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
