import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useUserLanguage from 'hooks/useUserLanguage';
import { formatDescriptionPreview } from 'utils/helpers';
import './style.scss';

const SelectableJobCard = ({ isDisabled, isSelected, onClick, onDetailClick, job }) => {
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();

    const title = job.title && job.title[userLanguage] ? job.title[userLanguage] : '';
    const shortDescription = job.shortDescription && job.shortDescription[userLanguage]
        ? formatDescriptionPreview(job.shortDescription[userLanguage])
        : '';

    return (
        <label
            className={classnames(
                'selectable-job-card__container',
                {
                    'selectable-job-card__container--selected': isSelected,
                    'selectable-job-card__container--disabled': isDisabled,
                },
            )}
            htmlFor={job.id}
        >
            <input
                id={job.id}
                onClick={() => { if (!isDisabled) { onClick(job.id, !isSelected); } }}
                type="checkbox"
            />
            <h4>{title}</h4>
            {shortDescription && <span className="selectable-job-card__description">{shortDescription}</span>}
            <div className="selectable-job-card__link">
                <button
                    onClick={() => {
                        onDetailClick(true, job);
                    }}
                    type="button"
                >
                    {t('jobCard.moreInformations')}
                </button>
            </div>
            <div className="selectable-job-card__favorite">
                <img
                    alt="favorite"
                    src={isDisabled ? '/assets/favorite-disabled.svg' : '/assets/favorite.svg'}
                />
            </div>
        </label>
    );
};

SelectableJobCard.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    job: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shortDescription: PropTypes.shape({}),
        title: PropTypes.shape({}).isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onDetailClick: PropTypes.func.isRequired,
};

export default SelectableJobCard;
