import PropTypes from 'prop-types';
import Modal from 'components/common/Modal';
import ShareButton from 'components/common/ShareButton';
import { FACEBOOK, LINKEDIN } from 'utils/shareOnSocialNetwork';
import './style.scss';

const ShareResultsModal = ({ shareUrl, onClose }) => (
    <Modal closeModal={onClose} fullscreen>
        <div className="base-margin share-buttons">
            <ShareButton
                buttonContent="LinkedIn"
                shareUrl={shareUrl}
                socialNetwork={LINKEDIN}
            />
            <ShareButton
                buttonContent="Facebook"
                shareUrl={shareUrl}
                socialNetwork={FACEBOOK}
            />
        </div>
    </Modal>
);

ShareResultsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    shareUrl: PropTypes.string.isRequired,
};

export default ShareResultsModal;
